import React, { useRef, useState } from 'react'
import { Box, Typography, useTheme, Avatar, Stack, IconButton } from '@mui/material'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import PersonIcon from '@mui/icons-material/Person'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import '../../message/MarkdownStyles.css'

const getFileType = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  if (['mp3', 'wav', 'ogg'].includes(extension)) {
    return 'audio';
  } else if (['jpg', 'jpeg', 'png', 'jfif', 'webp'].includes(extension)) {
    return 'image';
  }
  return 'unknown';
};

const Message = ({ text, sender }) => {
  const theme = useTheme()
  const [playingAudio, setPlayingAudio] = useState(null)
  const audioRefs = useRef({})

  const handlePlayPauseAudio = (url) => {
    const audio = audioRefs.current[url];
    if (!audio) return;

    if (playingAudio === url) {
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    } else {
      if (playingAudio) {
        audioRefs.current[playingAudio].pause();
      }
      audio.play();
      setPlayingAudio(url);
    }
  };

  const renderAssistantMessage = (content) => {
    if (typeof content === 'object') {
      return (
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          <code>{JSON.stringify(content, null, 2)}</code>
        </pre>
      );
    } else if (typeof content === 'string') {
      try {
        const parsedJson = JSON.parse(content);
        return (
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            <code>{JSON.stringify(parsedJson, null, 2)}</code>
          </pre>
        );
      } catch (error) {
        return <div className="markdown-body"><ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown></div>;
      }
    } else {
      return <Typography>{String(content)}</Typography>;
    }
  };

  const renderUserMessage = (content) => {
    let textContent = '';
    let mediaItems = [];

    if (Array.isArray(content)) {
      content.forEach(item => {
        if (item.type === 'text') {
          textContent = item.text;
        } else if (item.type === 'file') {
          mediaItems.push(item.file_url);
        }
      });
    } else {
      textContent = content;
    }

    return (
      <>
        <Typography>{textContent}</Typography>
        {mediaItems.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {mediaItems.map((item, index) => {
                const fileType = getFileType(item.url);
                if (fileType === 'image') {
                  return (
                    <img 
                      key={index} 
                      src={item.url} 
                      alt={`Attached image ${index + 1}`} 
                      style={{ objectFit: 'cover', borderRadius: '10px', height: '120px', width: '120px' }} 
                    />
                  );
                } else if (fileType === 'audio') {
                  return (
                    <Box
                      key={`audio-${index}`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        bgcolor: 'background.paper',
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: '16px',
                        px: 1,
                        pr: 2,
                        py: 0.5,
                      }}
                    >
                      <audio
                        ref={el => audioRefs.current[item.url] = el}
                        src={item.url}
                        onEnded={() => setPlayingAudio(null)}
                        style={{ display: 'none' }}
                      />
                      <IconButton
                        size="small"
                        onClick={() => handlePlayPauseAudio(item.url)}
                      >
                        {playingAudio === item.url && !audioRefs.current[item.url]?.paused ? (
                          <PauseIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                        ) : (
                          <PlayArrowIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                        )}
                      </IconButton>
                      <Typography variant="caption" sx={{ ml: 1, color: theme.palette.primary.main }}>
                        {item.url.split('/').pop()}
                      </Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <Stack
      direction={sender === 'user' ? 'row-reverse' : 'row'}
      alignItems="flex-start"
      sx={{
        maxWidth: '100%',
        mb: 1,
      }}
    >
      <Avatar
        sx={{
          bgcolor: sender === 'user' ? theme.palette.primary.main : theme.palette.primary.main,
          mr: sender === 'user' ? 0 : 1,
          ml: sender === 'user' ? 1 : 0,
          width: 28,
          height: 28,
        }}
      >
        {sender === 'user' ? <PersonIcon /> : <SmartToyIcon />}
      </Avatar>
      <Box
        sx={{
          maxWidth: 'calc(100% - 60px)',
          p: 1,
          borderRadius: 1,
          color: sender === 'user' ? theme.palette.primary.icon : theme.palette.secondary.textColor,
          bgcolor: sender === 'user' ? 'primary.light' : 'secondary.light',
          overflowX: 'auto',
          wordWrap: 'break-word',
        }}
      >
        {sender === 'user' ? renderUserMessage(text) : renderAssistantMessage(text)}
      </Box>
    </Stack>
  )
}

export default Message
