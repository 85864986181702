import React from 'react'
import { List, ListItemText, Typography, ListItemIcon, ListItem } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Key, VpnKey} from '@mui/icons-material'
import { FlexBox, MainBox, StyledListItem, StyledSettingsIcon } from './sideBarStyles'

const Sidebar = ({ onSelect, selected }) => {
  const theme = useTheme()

  const handleClick = (section) => {
    if (onSelect) {
      onSelect(section)
    }
  }

  return (
    <MainBox>
      <FlexBox>
        <StyledSettingsIcon />
        <Typography variant="h6">Settings</Typography>
      </FlexBox>
      <List>
        <StyledListItem button onClick={() => handleClick('keyConfiguration')} selectedP={selected === 'keyConfiguration'}>
          <ListItemIcon>
            <VpnKey style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText primary="Key Configuration" />
        </StyledListItem>
        <StyledListItem button onClick={() => handleClick('apiKeys')} selectedP={selected === 'apiKeys'}>
          <ListItemIcon>
            <Key style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText primary="API Keys" />
        </StyledListItem>
        <StyledListItem button onClick={() => handleClick('knowledgeBaseKeys')} selectedP={selected === 'knowledgeBaseKeys'}>
          <ListItemIcon>
            <Key style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText primary="Knowledge Base Keys" />
        </StyledListItem>
        {/* <ListItem
          button
          onClick={() => handleClick('billingUsage')}
          sx={{
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: theme.palette.drawerButton,
            },
            borderRadius: 3,
            my: 1,
            backgroundColor: selected === 'billingUsage' && theme.palette.drawerButtonBright,
          }}
        >
          <ListItemIcon>
            <BillingIcon style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText primary="Billing and Usage" />
        </ListItem>
        <ListItem
          button
          onClick={() => handleClick('subscription')}
          sx={{
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: theme.palette.drawerButton,
            },
            borderRadius: 3,
            my: 1,
            backgroundColor: selected === 'subscription' && theme.palette.drawerButtonBright,
          }}
        >
          <ListItemIcon>
            <SubscriptionIcon style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText primary="Subscription" />
        </ListItem>

        <ListItem
          button
          onClick={() => handleClick('databaseConfig')}
          sx={{
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: theme.palette.drawerButton,
            },
            borderRadius: 3,
            my: 1,
            backgroundColor: selected === 'databaseConfig' && theme.palette.drawerButtonBright,
          }}
        >
          <ListItemIcon>
            <DatabaseIcon style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText primary="Database Config" />
        </ListItem> */}
      </List>
    </MainBox>
  )
}

export default Sidebar
