import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  List,
  ListItemText,
  Collapse,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  TextField,
  Box,
  InputAdornment,
  Paper,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import FolderIcon from '@mui/icons-material/Folder'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import ClearIcon from '@mui/icons-material/Clear'
import SearchOffIcon from '@mui/icons-material/SearchOff'

import {
  createFolder,
  createPrompt,
  deactivateFolder,
  deactivatePrompt,
  updateFolderName,
  updatePromptName,
} from '../../redux/action/UserProjectsAction'

import { useAuth } from '../../AuthContext'
import {
  setOpenFolderId,
  setProjectsData,
  setPromptVersions,
  setSelectedFolderId,
  setSelectedFolderName,
  setSelectedPrompt,
  setSelectedPromptVersion,
} from '../../redux/slice/UserProjects'

import AddItemModal from '../modal/addItemModal'
import RenameModal from '../modal/renameModal'
import { useTheme } from '@mui/material'
import {
  BoldText,
  Search,
  SearchIconWrapper,
  StyledClearIcon,
  StyledInputBase,
  StyledListItem,
  StyledListItemPrompt,
  StyledListItemText,
  StyledSearchIcon,
  StyledSearchOffIcon,
  StyledTooltip,
} from './folderListStyles'

import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
  setChatSummary,
  setIsPublished,
  setMessages,
  setMessagesInitial,
  setPropmtData,
  setSelectedModel,
  setSystemValue,
  setVariableKey,
  setVariables,
} from '../../redux/slice/Conversation'
import { getPromptDetails, getPromptVersions } from '../../redux/action/PlaygroundAction'
import { AI_MODELS, NAMING_MAX, NAMING_MIN } from '../../constants/Constants'
import { useTranslation } from 'react-i18next'
import CreateNewPrompt from '../dialog/createNewPrompt'
import { v4 as uuidv4 } from 'uuid'

const FolderList = ({ fetchProjectDetails }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const { t, ready } = useTranslation()
  const Theme = useTheme()
  const {
    selectedProjectId,
    selectedFolderId,
    projectsData,
    selectedPrompt,
    selectedFolderName,
    openFolderId,
  } = useSelector((state) => state.UserProjects)

  const [folderContextMenu, setFolderContextMenu] = useState(null)
  const [promptContextMenu, setPromptContextMenu] = useState(null)

  const [renameFolderText, setRenameFolderText] = useState('')
  const [renameFolderModalOpen, setRenameFolderModalOpen] = useState(false)
  const [folderRenameId, setFolderRenameId] = useState('')

  const [renamePromptText, setRenamePromptText] = useState('')
  const [renamePromptModalOpen, setRenamePromptModalOpen] = useState(false)
  const [promptRenameId, setPromptRenameId] = useState('')

  const [folderName, setFolderName] = useState('')
  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false)

  const [promptName, setPromptName] = useState('')
  const [addPromptModalOpen, setAddPromptModalOpen] = useState(false)

  const [promptFolder, setPromptFolder] = useState('')

  const [addLoading, setAddLoading] = useState(false)
  const [renameLoading, setRenameLoading] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')

  const [searchToggle, setSearchToggle] = useState(false)

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSearchToggle = (e) => {
    setSearchToggle(!searchToggle)
  }

  const filteredProjects = projectsData?.folders?.filter((folder) => {
    const folderNameMatches = folder.folderName.toLowerCase().includes(searchQuery.toLowerCase())

    const promptsExist = folder.prompts && Array.isArray(folder.prompts)
    const promptNameMatches =
      promptsExist &&
      folder.prompts.some((prompt) =>
        prompt.promptName.toLowerCase().includes(searchQuery.toLowerCase()),
      )

    return folderNameMatches || promptNameMatches
  })

  useEffect(() => {
    if (searchQuery.length > 1) {
      dispatch(setOpenFolderId(filteredProjects[0]?._id))
    }
  }, [searchQuery])

  const handleCloseAddFolderModal = () => setAddFolderModalOpen(false)

  const handleAddFolderChangeInput = (e) => {
    setFolderName(e.target.value)
  }
  const handleOpenAddFolderModal = () => setAddFolderModalOpen(true)

  const handleCloseRenameFolderModal = () => setRenameFolderModalOpen(false)
  const handleCloseRenamePromptModal = () => setRenamePromptModalOpen(false)

  const handleFolderNameChange = (e) => {
    setRenameFolderText(e.target.value)
  }
  const handlePromptNameChange = (e) => {
    setRenamePromptText(e.target.value)
  }

  const handleCloseAddPromptModal = () => setAddPromptModalOpen(false)
  const handleAddPromptChangeInput = (e) => {
    setPromptName(e.target.value)
  }
  const handleOpenAddPromptModal = () => setAddPromptModalOpen(true)

  const handleClick = (folderId, folderName) => {
    if (openFolderId === folderId) {
      dispatch(setOpenFolderId(null))
    } else {
      dispatch(setOpenFolderId(folderId))
    }
    dispatch(setSelectedFolderId(folderId))
    dispatch(setSelectedFolderName(folderName))
  }

  const handleAddPromtClick = (id) => {
    setPromptFolder(id)
    if (!selectedFolderId || !selectedFolderId.length) {
      toast.warning('Please select a folder first')
    } else {
      handleOpenAddPromptModal()
    }
  }

  const handleFolderContextMenuClick = (event, folder) => {
    event.preventDefault()
    event.stopPropagation()
    setFolderContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      folder,
    })
  }

  const handlePromptContextMenuClick = (event, folderId, prompt) => {
    event.preventDefault()
    event.stopPropagation()
    setPromptContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      folderId,
      prompt,
    })
  }

  const handleCloseFolderContextMenu = () => {
    setFolderContextMenu(null)
  }

  const handleClosePromptContextMenu = () => {
    setPromptContextMenu(null)
  }

  const handleFolderRename = (folderId, folderName) => {
    setRenameFolderModalOpen(true)
    setFolderRenameId(folderId)
    setRenameFolderText(folderName)
    handleCloseFolderContextMenu()
  }

  const handlePromptRename = (folderId, promptId, promptName) => {
    setRenamePromptText(promptName)
    setRenamePromptModalOpen(true)
    setFolderRenameId(folderId)
    setPromptRenameId(promptId)
    handleClosePromptContextMenu()
  }

  const handlePromptClick = (prompt, folder) => {
    // fetchPromptVersions(prompt, folder)

    if (prompt?._id === selectedPrompt?.promptId) {
      return
    }

    dispatch(setMessagesInitial([]))
    dispatch(setSelectedModel(AI_MODELS[0]))
    dispatch(setVariables([]))
    dispatch(setVariableKey([]))
    dispatch(setSystemValue(''))

    const newSelectedPrompt = {
      promptId: prompt?._id,
      promptName: prompt?.promptName,
      promptFolderId: folder?._id,
      promptFolderName: folder?.folderName,
    }

    setSearchToggle(false)
    setSearchQuery('')
    dispatch(setSelectedPrompt(newSelectedPrompt))
    // fetchPromptDetails(prompt, folder)
  }

  const handleDeleteFolderConfirmation = (id, name) => {
    handleCloseFolderContextMenu()
    Swal.fire({
      title: 'Confirm Deletion',
      text: `Are you sure you want to delete the folder "${name}"? This action will also permanently delete all prompts and their versions contained within the folder.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        handleFolderDelete(id)
      } else {
        // example codes to clear up
      }
    })
  }

  const handleDeletePromptConfirmation = (folderId, promptId, promptName) => {
    handleClosePromptContextMenu()
    Swal.fire({
      title: 'Confirm Deletion',
      text: `Are you sure you want to delete prompt "${promptName}" ? This action will permanently delete the prompt and all of its versions.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        handlePromptDelete(folderId, promptId)
      } else {
        // example codes to clear up
      }
    })
  }
  // API CALL FUNCTIONS

  const handleAddFolderSubmit = () => {
    setAddLoading(true)
    const name = folderName
    const userId = userInfo?.userId
    const projectId = selectedProjectId
    try {
      dispatch(
        createFolder({
          name,
          userId,
          projectId,
          meta: {
            onSuccess: async (res) => {
              toast.success(res.message)
              handleCloseAddFolderModal()
              setFolderName('')
              // save project data
              const existingFolders = projectsData?.folders || []
              const updatedFolders = [...existingFolders, res?.data?.folder]
              dispatch(setProjectsData({ ...projectsData, folders: updatedFolders }))
              await new Promise((resolve) => setTimeout(resolve, 100))
              const selectedPrompt = {
                promptId: res?.data?.folder.prompts[0]._id,
                promptName: res?.data?.folder.prompts[0].promptName,
                promptFolderId: res?.data?.folder._id,
                promptFolderName: res?.data?.folder.folderName,
              }
              dispatch(setSelectedPrompt(selectedPrompt))
              dispatch(setSelectedFolderId(res?.data?.folder._id))
              dispatch(setSelectedFolderName(res?.data?.folder.folderName))
              dispatch(setOpenFolderId(res?.data?.folder._id))
              dispatch(
                setPromptVersions({
                  [res?.data?.prompt_version.version_id]: 1.0,
                }),
              )
              dispatch(setSelectedPromptVersion(res?.data?.prompt_version.version_id))
              setAddLoading(false)
            },
            onError: (error) => {
              toast.error(error.data.message)
              setAddLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during Updating:', error)
    }
  }

  const handleRemoveVariable = (id) => {
    dispatch(
      setVariables((prevVariables) => prevVariables.filter((variable) => variable.id !== id)),
    )
  }

  const handleAddPromptSubmit = (selectedTemplate = '') => {
    setAddLoading(true)
    const name = promptName
    const userId = userInfo?.userId
    const projectId = selectedProjectId
    const folderId = selectedFolderId

    try {
      dispatch(
        createPrompt({
          name,
          userId,
          projectId,
          folderId,
          meta: {
            onSuccess: (data) => {
              const newSelectedPrompt = {
                promptId: data?.id,
                promptName: data?.promptName,
                promptFolderId: selectedFolderId,
                promptFolderName: selectedFolderName,
              }
              toast.success(data?.message)
              dispatch(setSelectedPrompt(newSelectedPrompt))
              dispatch(setSelectedModel(AI_MODELS[0]))
              dispatch(setMessagesInitial([]))
              dispatch(setSystemValue(selectedTemplate))
              dispatch(setChatSummary(''))
              dispatch(setIsPublished(false))
              dispatch(setVariableKey([]))
              dispatch(setVariables([]))

              dispatch(setOpenFolderId(selectedFolderId))

              handleCloseAddPromptModal()
              setPromptName('')
              fetchProjectDetails(userId, projectId)
              setAddLoading(false)
              dispatch(setPromptVersions({}))
            },
            onError: (error) => {
              toast.error(error?.data?.message)
              console.error('Adding failed:', error)
              setAddLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during Updating:', error)
    }
  }

  const handleRenameFolderSubmit = () => {
    setRenameLoading(true)
    const name = renameFolderText
    const userId = userInfo?.userId
    const projectId = selectedProjectId
    const folderId = folderRenameId
    try {
      dispatch(
        updateFolderName({
          name,
          userId,
          projectId,
          folderId,
          meta: {
            onSuccess: (data) => {
              toast.success(data?.message)
              handleCloseRenameFolderModal()
              setRenameFolderText('')
              fetchProjectDetails(userId, projectId)
              dispatch(setSelectedPrompt({ ...selectedPrompt, promptFolderName: name }))
              setRenameLoading(false)
            },
            onError: (error) => {
              if (error.data.status_code === 409) {
                toast.z(error.data.message)
              } else {
                console.error('Updating failed:', error)
              }
              setRenameLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      toast.error(error?.data?.message)
      console.error('An error occurred during Updating:', error)
    }
  }
  const handleRenamePromptSubmit = () => {
    setRenameLoading(true)
    const name = renamePromptText
    const userId = userInfo?.userId
    const projectId = selectedProjectId
    const folderId = folderRenameId
    const promptId = promptRenameId
    try {
      dispatch(
        updatePromptName({
          name,
          userId,
          projectId,
          folderId,
          promptId,
          meta: {
            onSuccess: (data) => {
              toast.success(data.message)
              // alert('Prompt renamed successfully')
              handleCloseRenamePromptModal()
              setRenamePromptText('')
              fetchProjectDetails(userId, projectId)
              dispatch(setSelectedPrompt({ ...selectedPrompt, promptName: name }))
              setRenameLoading(false)
            },
            onError: (error) => {
              if (error.data.status_code === 409) {
                toast.warning(error.data.message)
              } else {
                console.error('Updating failed:', error)
              }
              setRenameLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during Updating:', error)
    }
  }

  const handleFolderDelete = (folderId) => {
    // Handle folder delete logic

    const userId = userInfo?.userId
    const projectId = selectedProjectId
    try {
      dispatch(
        deactivateFolder({
          projectId,
          userId,
          folderId,
          meta: {
            onSuccess: (data) => {
              toast.success(data.message)
              fetchProjectDetails(userId, projectId)
            },
            onError: (error) => {
              console.error('Deleting failed:', error)
              toast.error(error?.data?.message)
            },
          },
        }),
      )
    } catch (error) {
      toast.error(error?.data?.message)
      console.error('An error occurred during Deleting:', error)
    }
    handleCloseFolderContextMenu()
  }

  const handlePromptDelete = async (folderId, promptId) => {
    // const fetchPromptDetailsDelete = (folderId, promptId) => {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       dispatch(
    //         getPromptDetails({
    //           userId: userInfo?.userId,
    //           projectId: selectedProjectId,
    //           folderId,
    //           promptId,
    //           meta: {
    //             onSuccess: (res) => {
    //               if (res?.status === 200) {
    //                 resolve(true)
    //               } else {
    //                 resolve(false)
    //               }
    //             },
    //             onError: (error) => {
    //               if (error?.status === 404) {
    //                 resolve(false)
    //               } else {
    //                 reject(error)
    //               }
    //             },
    //           },
    //         }),
    //       )
    //     } catch (error) {
    //       reject(error)
    //     }
    //   })
    // }

    try {
      // const is_Published = await fetchPromptDetailsDelete(folderId, promptId)

      // if (!is_Published) {
      const userId = userInfo?.userId
      const projectId = selectedProjectId

      dispatch(
        deactivatePrompt({
          projectId,
          userId,
          folderId,
          promptId,
          meta: {
            onSuccess: (data) => {
              toast.success(data.message)
              fetchProjectDetails(userId, projectId)
              const latestFolder = projectsData?.folders?.find((fold) => fold._id === folderId)
              const latestIndex = latestFolder?.prompts?.findIndex(
                (prompt) => prompt?._id === promptId,
              )
              const promptIndex = latestIndex === 0 ? 2 : latestIndex

              const newSelectedPrompt = {
                promptId: latestFolder?.prompts[promptIndex - 1]?._id,
                promptName: latestFolder?.prompts[promptIndex - 1]?.promptName,
                promptFolderId: latestFolder?._id,
                promptFolderName: latestFolder?.folderName,
              }
              dispatch(setSelectedPrompt(newSelectedPrompt))
              // fetchPromptDetails(latestFolder?.prompts[promptIndex - 1], latestFolder)
            },
            onError: (error) => {
              toast.error(error?.data?.message)
              console.error('Deleting failed:', error)
            },
          },
        }),
      )
      // } else {
      //   toast.warning("Published prompts can't be deleted. Delete feature coming soon!")
      // }

      handleClosePromptContextMenu()
    } catch (error) {
      toast.error(error?.data?.message)
      console.error('An error occurred during the prompt deletion process:', error)
    }
  }

  // model validation
  const [error, setError] = useState('')
  const validateInput = (value) => {
    if (!value.trim()) {
      setError('Name is required')
      return false
    }
    if (value.trim().length < NAMING_MIN) {
      setError(`Name must be at least ${NAMING_MIN} characters long`)
      return false
    }
    if (value.trim().length > NAMING_MAX) {
      setError(`Name must be less than ${NAMING_MAX} characters`)
      return false
    }
    if (/^\d/.test(value.trim())) {
      setError('Name cannot start with a number')
      return false
    }
    setError('')
    return true
  }

  return (
    <>
      <Stack direction={'column'}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ mt: 3 }}>
          <BoldText marginLeft={2}>Prompts</BoldText>
          <Stack direction={'row'} spacing={2}>
            {searchToggle && (
              <Tooltip title="Hide Search" arrow placement="bottom">
                <StyledSearchOffIcon onClick={handleSearchToggle} />
              </Tooltip>
            )}
            {!searchToggle && (
              <Tooltip title="Search" arrow placement="bottom">
                <StyledSearchIcon onClick={handleSearchToggle} />
              </Tooltip>
            )}
            <Tooltip title="Add New Folder" arrow>
              <CreateNewFolderIcon onClick={handleOpenAddFolderModal} sx={{ cursor: 'pointer' }} />
            </Tooltip>
            <Tooltip title="Add New Prompt" arrow>
              <NoteAddIcon onClick={handleAddPromtClick} sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
        </Stack>
        <Box sx={{ mt: 2 }}>
          {searchToggle && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {searchQuery && <StyledClearIcon onClick={() => setSearchQuery('')} />}
            </Search>
          )}
        </Box>
      </Stack>
      <List>
        {filteredProjects && filteredProjects?.length === 0 && (
          <h4 style={{ marginLeft: '1.5rem' }}> No Records Found</h4>
        )}
        {filteredProjects?.map((folder) => (
          <div key={folder?._id}>
            <StyledListItem
              selectedFolder={selectedFolderId === folder?._id}
              isPromptSelected={selectedFolderId === selectedPrompt.promptFolderId}
              isOpenFolder={openFolderId === folder?._id}
              onClick={() => handleClick(folder?._id, folder?.folderName)}
            >
              <Stack direction={'row'} width={'30px'}>
                {folder.prompts &&
                  folder.prompts.length > 0 &&
                  (openFolderId === folder?._id ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  ))}
              </Stack>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <FolderIcon />
              </ListItemIcon>
              <StyledTooltip title={folder.folderName}>
                <ListItemText
                  primary={<StyledListItemText noWrap>{folder.folderName}</StyledListItemText>}
                />
              </StyledTooltip>
              <MoreHorizIcon onClick={(event) => handleFolderContextMenuClick(event, folder)} />
            </StyledListItem>
            {folder.prompts && folder.prompts.length > 0 && (
              <Collapse in={openFolderId === folder?._id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {folder.prompts.map((prompt) => {
                    return (
                      <StyledListItemPrompt
                        key={prompt?._id}
                        selectedPrompt={selectedPrompt && selectedPrompt?.promptId === prompt?._id}
                        onClick={() => handlePromptClick(prompt, folder)}
                      >
                        <ListItemIcon sx={{ minWidth: '30px' }}>
                          <InsertDriveFileIcon />
                        </ListItemIcon>
                        <StyledTooltip title={prompt.promptName}>
                          <ListItemText
                            primary={
                              <StyledListItemText noWrap>{prompt.promptName}</StyledListItemText>
                            }
                          />
                        </StyledTooltip>
                        <MoreHorizIcon
                          onClick={(event) =>
                            handlePromptContextMenuClick(event, folder?._id, prompt)
                          }
                        />
                      </StyledListItemPrompt>
                    )
                  })}
                </List>
              </Collapse>
            )}
          </div>
        ))}
        {folderContextMenu && (
          <Menu
            open={Boolean(folderContextMenu)}
            onClose={handleCloseFolderContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              folderContextMenu !== null
                ? { top: folderContextMenu.mouseY, left: folderContextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem
              onClick={() =>
                handleFolderRename(
                  folderContextMenu?.folder?._id,
                  folderContextMenu.folder.folderName,
                )
              }
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleDeleteFolderConfirmation(
                  folderContextMenu?.folder?._id,
                  folderContextMenu.folder.folderName,
                )
              }
            >
              Delete
            </MenuItem>
          </Menu>
        )}
        {promptContextMenu && (
          <Menu
            open={Boolean(promptContextMenu)}
            onClose={handleClosePromptContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              promptContextMenu !== null
                ? { top: promptContextMenu.mouseY, left: promptContextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem
              onClick={() =>
                handlePromptRename(
                  promptContextMenu.folderId,
                  promptContextMenu.prompt?._id,
                  promptContextMenu.prompt?.promptName,
                )
              }
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleDeletePromptConfirmation(
                  promptContextMenu.folderId,
                  promptContextMenu.prompt?._id,
                  promptContextMenu.prompt?.promptName,
                )
              }
            >
              Delete
            </MenuItem>
          </Menu>
        )}
      </List>
      <RenameModal
        name={renameFolderText}
        open={renameFolderModalOpen}
        handleClose={handleCloseRenameFolderModal}
        handleChangeInput={handleFolderNameChange}
        handleSubmit={handleRenameFolderSubmit}
        loading={renameLoading}
        error={error}
        setError={setError}
        setLoading={setRenameLoading}
        validateInput={validateInput}
      />
      <RenameModal
        name={renamePromptText}
        open={renamePromptModalOpen}
        handleClose={handleCloseRenamePromptModal}
        handleChangeInput={handlePromptNameChange}
        handleSubmit={handleRenamePromptSubmit}
        loading={renameLoading}
        error={error}
        setError={setError}
        setLoading={setRenameLoading}
        validateInput={validateInput}
      />
      <AddItemModal
        title="Create a new Folder"
        body="Folders help you organize and categorize your prompts within a project. Create a new folder to better manage and structure your project's content, making it easier to find and use specific prompts."
        name={folderName}
        handleChangeInput={handleAddFolderChangeInput}
        open={addFolderModalOpen}
        setOpen={setAddFolderModalOpen}
        handleClose={handleCloseAddFolderModal}
        handleSubmit={handleAddFolderSubmit}
        validateInput={validateInput}
        error={error}
        setError={setError}
        loading={addLoading}
        setLoading={setAddLoading}
      />
      <AddItemModal
        title="Create a new Prompt"
        body="Prompts are shared environments where teams can collaborate and share API resources. You can set custom rate limits and manage access to resources."
        name={promptName}
        handleChangeInput={handleAddPromptChangeInput}
        open={addPromptModalOpen}
        setOpen={handleOpenAddPromptModal}
        handleClose={handleCloseAddPromptModal}
        handleSubmit={handleAddPromptSubmit}
        validateInput={validateInput}
        error={error}
        setError={setError}
        loading={addLoading}
        setLoading={setAddLoading}
      />
    </>
  )
}

export default FolderList
