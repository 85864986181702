import React, { useEffect, useState } from 'react'
import { Item, LightText, StyledDrawer, StyledList, Subheader } from './studioDrawerStyles'
import FolderList from '../folderList'
import { DATA_SOURCE_LIST, USEISMENU } from '../../constants/Constants'
import { Box, ListItemText, Stack, Toolbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectDetails } from '../../redux/action/UserProjectsAction'
import { setProjectsData } from '../../redux/slice/UserProjects'
import { useAuth } from '../../AuthContext'
import ComingSoon from '../ComingSoon'
import SingleDialog from '../dialog/singleDialog'
import { useTranslation } from 'react-i18next'

const StudioDrawer = ({ studioDrawerOpen, toggleDrawer }) => {
  const { selectedProjectId, selectedProject } = useSelector((state) => state.UserProjects)
  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const [open, setOpen] = useState(false)

  const isMenu = USEISMENU()

  const { t, ready } = useTranslation()

  const fetchProjectDetails = (userId, projectId) => {
    try {
      dispatch(
        getProjectDetails({
          userId,
          projectId,
          meta: {
            onSuccess: (data) => {
              const temp = data?.data?.project
              dispatch(setProjectsData(temp))
            },
            onError: (error) => {
              console.error('Fetching failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during login:', error)
    }
  }

  const dataSourceListTrans = DATA_SOURCE_LIST(t, ready)

  // useEffect(() => {
  //   if (userInfo?.userId) {
  //     fetchProjectDetails(userInfo?.userId, selectedProjectId)
  //   }
  // }, [userInfo, selectedProjectId])

  return (
    // <StyledDrawer variant="permanent" anchor="left">
    <StyledDrawer
      anchor="left"
      open={isMenu ? studioDrawerOpen : true}
      onClose={isMenu ? () => toggleDrawer(false) : undefined}
      {...(!isMenu && { variant: 'permanent' })}
    >
      <StyledList component="nav">
        <Stack height={'64px'} justifyContent={'flex-start'} alignItems={'end'}>
          <CloseIcon onClick={() => toggleDrawer(false)} sx={{ cursor: 'pointer' }} />
        </Stack>
        <FolderList fetchProjectDetails={fetchProjectDetails} />
        {/* {dataSourceListTrans?.map((section, index) => (
          <Box key={index}>
            <Subheader onClick={() => setOpen(true)}>{section.category}</Subheader>
            {section.items.length > 0 &&
              section?.items?.map((item, idx) => (
                <Item key={idx} sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
                  <ListItemText primary={<LightText noWrap>{item}</LightText>} />
                </Item>
              ))}
          </Box>
        ))} */}
      </StyledList>
      <SingleDialog
        DialogContentComponent={<ComingSoon />}
        handleClose={() => setOpen(false)}
        open={open}
      />
    </StyledDrawer>
  )
}

export default StudioDrawer
