import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useTheme,
  styled,
  Stack,
} from '@mui/material'
import CommonStyles from '../../../assets/styles/CommonStyles'
import { LoginSocialGoogle } from 'reactjs-social-login'
import { signIn } from '../../../redux/action/AuthAction'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setDialogOpen, setIsNewUser, setLogIn } from '../../../redux/slice/common'
import { useContext } from 'react'
import AuthContext from '../../../AuthContext'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../router/Navigation'
import { setChatGPTKey, setClaudeKey, setGeminiKey } from '../../../redux/slice/UserSettings'
import { getUserKeys } from '../../../redux/action/UserSettingsAction'
import GoogleIcon from '@mui/icons-material/Google' // MUI Icon for Google
import FacebookIcon from '@mui/icons-material/Facebook'
import { Icons } from '../../../assets/image'
import CloseIcon from '@mui/icons-material/Close' // Import the CloseIcon
import { USEISMOBILE } from '../../../constants/Constants'

const NewGoogleButton = styled(LoginSocialGoogle)(({ theme }) => ({
  //   padding: '10px 10px',
  borderRadius: 50,
  color: theme.palette.textColor,
  zIndex: 1099,
  border: '1px solid #e0e0e0',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  userSelect: 'none',
  '& div': {
    padding: theme.breakpoints.only('sm') ? '5px' : '8px',
  },
  '&:hover': {
    border: `1px solid ${theme.palette.landingPage.hero.button}`,
  },
}))

const LoginDialog = ({ open, onClose }) => {
  const theme = useTheme()
  const { login } = useContext(AuthContext)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const isMobile = USEISMOBILE()

  const getKeys = (userID) => {
    try {
      dispatch(
        getUserKeys({
          userId: userID,
          meta: {
            onSuccess: (data) => {
              const keys = data?.data?.modelKeys || []
              const updatedKeys = {
                chatGPTKey: '',
                claudeKey: '',
                geminiKey: '',
              }
              keys.forEach((key) => {
                switch (key.model) {
                  case 'openai':
                    updatedKeys.chatGPTKey = key.modelKey
                    break
                  case 'claude':
                    updatedKeys.claudeKey = key.modelKey
                    break
                  case 'gemini':
                    updatedKeys.geminiKey = key.modelKey
                    break
                  default:
                    break
                }
              })
              dispatch(setChatGPTKey(updatedKeys.chatGPTKey))
              dispatch(setClaudeKey(updatedKeys.claudeKey))
              dispatch(setGeminiKey(updatedKeys.geminiKey))
            },
            onError: (error) => {
              console.error('Fetching keys failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const handleLoginSuccess = async (token) => {
    try {
      dispatch(
        signIn({
          token,
          meta: {
            onSuccess: async (data) => {
              if (data?.status === 201) {
                await login(data?.data)
                dispatch(setLogIn(true))
                dispatch(setIsNewUser(true))
                dispatch(setDialogOpen(true))
                toast.success("Congratulations! You've successfully logged in")
                navigate(navigationRoutes.user.workspace)
              } else if (data?.status === 200) {
                await login(data?.data)
                dispatch(setIsNewUser(false))
                dispatch(setDialogOpen(true))
                dispatch(setLogIn(true))
                getKeys(data?.data?.data?.user?.userId)
                toast.success("Great to see you again! You're now logged in")
                navigate(navigationRoutes.user.workspace)
              }
            },
            onError: (error) => {
              console.error('Login failed:', error)
              toast.error('Login failed. Please try again.')
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during login:', error)
      toast.error('An error occurred during login')
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      sx={{ '& div': { padding: '12px', borderRadius: '12px' } }}
    >
      <DialogTitle
        sx={{
          fontSize: isMobile ? 24 : 32,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }} // Position the close icon
        >
          <CloseIcon />
        </IconButton>
        {theme.palette.mode === 'light' ? (
          <CommonStyles.CompanyMainLogo />
        ) : (
          <CommonStyles.CompanyLogoWhite />
        )}
        Let’s Get Started
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: 15 }}>
            We're glad to see you again! Please log in to continue.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: '1px' }}
      >
        <NewGoogleButton
          isOnlyGetToken
          client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          scope="profile email"
          onResolve={({ provider, data }) => {
            handleLoginSuccess(data?.access_token)
          }}
          onReject={(err) => {
            toast.error(err)
          }}
          onClick={onClose}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <CommonStyles.GoogleIcon />
            Sign in with Google
          </Stack>
        </NewGoogleButton>
      </DialogActions>
    </Dialog>
  )
}

export default LoginDialog
