import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { API_TIMEOUT } from '../../constants/Constants'
import { refreshToken } from '../refreshToken'
import { getStoredData } from '../decodeUser'
import { navigationRoutes } from '../../router/Navigation'
import { toast } from 'react-toastify'
import { getUserProjects } from '../../redux/action/UserProjectsAction'
import { setSelectedProject, setSelectedProjectId, setUserProjects } from '../../redux/slice/UserProjects'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = getStoredData()
    const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
    
    // Try to get selectedUserId from localStorage
    let selectedUserId = localStorage.getItem(STORAGE.SELECTED_USER_ID)
    
    // If not found, try to get it from stored user info
    if (!selectedUserId) {
      const encodedUserInfo = localStorage.getItem(STORAGE.USER_INFO)
      if (encodedUserInfo) {
        try {
          const userInfo = JSON.parse(atob(encodedUserInfo))
          selectedUserId = userInfo.email
          // Set it in localStorage for future use
          localStorage.setItem(STORAGE.SELECTED_USER_ID, selectedUserId)
        } catch (error) {
          console.error('Error parsing user info:', error)
        }
      }
    }
    
    if (accessToken && (!config.headers || !config.headers.Authorization)) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    
    // Add selectedUserId to headers if it exists
    if (selectedUserId) {
      config.headers.selectedUserId = selectedUserId
    }
    
    return config
  },
  (error) => Promise.reject(error),
)

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response) {
      if (error.response.status === 401) {
        // Attempt to refresh the token if it's a 401 error
        const newAccessToken = await refreshToken()
        if (newAccessToken) {
          // Retry the original request with the new access token
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
          return axiosInstance(originalRequest)
        } else {
          // Redirect to login if unable to refresh token
          window.location.href = navigationRoutes.main.landingPage
        }
      } else if (error.response.status === 403) {
        localStorage.clear()
        window.location.href = navigationRoutes.main.landingPage
        toast.warning("Access denied. Please log in again.")
      } else if (error.response.status === 500) {
        console.error('Internal Server Error:', error.response)
      }
    } else if (error.request) {
      if (error.config.url.includes('chat_with_prompt')) {
        toast.error('chat provider is experiencing some issues, please try again later')
      } else {
        toast.error('Something went wrong')
      }
    } else {
      console.error('Error setting up request:', error.message)
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
