import React from 'react'
import { Dialog, IconButton, useTheme, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const SingleDialog = ({ DialogContentComponent, handleClose, open }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          m: fullScreen ? 0 : 2,
          borderRadius: fullScreen ? 0 : '12px',
          height: fullScreen ? '100%' : 'auto',
        }
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'text.secondary',
        }}
      >
        <CloseIcon />
      </IconButton>
      {DialogContentComponent}
    </Dialog>
  )
}

export default SingleDialog
