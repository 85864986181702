import {
  Button,
  InputBase,
  ListItem,
  ListItemIcon,
  styled,
  Typography,
  Tooltip,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import ClearIcon from '@mui/icons-material/Clear'

const AddButton = styled(Button)(({ theme }) => ({
  //   height: '40px',
  width: '100%',
}))

const BoldText = styled(Typography)(({ theme }) => ({
  color: theme.palette.studio.leftTitle,
  fontSize: '1.1rem',
  fontWeight: 'bold',
  borderRadius: '8px',
}))

const StyledListItemText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '160px',
}))

const StyledListItem = styled(ListItem)(
  ({ theme, selectedFolder, isPromptSelected, isOpenFolder }) => ({
    backgroundColor:
      selectedFolder && (!isOpenFolder || (isOpenFolder && !isPromptSelected))
        ? theme.palette.drawerButton
        : '',
    marginLeft: '2px',
    marginRight: '2px',
    marginTop: '4px',
    marginBottom: '4px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.drawerButtonHover,
    },
    cursor: 'pointer',
  }),
)

const StyledListItemPrompt = styled(ListItem)(({ theme, selectedPrompt }) => ({
  backgroundColor: selectedPrompt ? theme.palette.drawerButton : '',
  margin: '2px',
  borderRadius: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.drawerButtonHover,
  },
  paddingLeft: '64px',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: theme.palette.textColor,
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.drawerButtonHover
      : theme.palette.secondary.light,
  width: '100%',
}))

const StyledSearchOffIcon = styled(SearchOffIcon)(({ theme }) => ({
  cursor: 'pointer',
}))

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  cursor: 'pointer',
}))

const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  position: 'absolute',
  right: 25,
  top: 8,
  cursor: 'pointer',
  padding: '2px',
  color: theme.palette.primary.main,
}))

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '30px',
}))

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'grey' // Light mode: #314584 (navy blue)
        : 'black', // Dark mode: #333436 (dark gray)
    color:
      theme.palette.mode === 'light'
        ? theme.palette.white // Light mode: white text
        : theme.palette.textColor, // Dark mode: white text
    fontSize: '0.875rem',
    padding: '8px 12px',
    borderRadius: '4px',
  },
}))

export {
  AddButton,
  BoldText,
  StyledListItemText,
  StyledListItem,
  StyledListItemPrompt,
  SearchIconWrapper,
  StyledInputBase,
  Search,
  StyledSearchOffIcon,
  StyledSearchIcon,
  StyledClearIcon,
  StyledListItemIcon,
  StyledTooltip,
}
