import { createSlice } from '@reduxjs/toolkit'
import { getPromptDetails, getVersionDetails } from '../action/PlaygroundAction'
import {
  AI_MODELS,
  DEFAULT_PARAMETERS,
  DEFAULT_PARAMETERS_1,
  JSON_SCHEMA_PLACEHOLDER,
} from '../../constants/Constants'

const initialStates = {
  conversation: {},
  isLoading: false,
  isVersionLoading: false,
  isPublished: false,

  promptData: {},
  selectedModel: AI_MODELS[0],

  messages: [],
  messagesCompare: [],

  systemValue: '',
  userValue: '',
  assistantValue: '',
  chatSummary: '',

  promptDataCompare: {},
  selectedModelCompare: AI_MODELS[0],
  systemValueCompare: '',
  userValueCompare: '',
  assistantValueCompare: '',
  chatSummaryCompare: '',

  selectedAi: '',
  selectedAiCompare: '',

  selectedKey: '',
  parameters: DEFAULT_PARAMETERS,
  parametersCompare: DEFAULT_PARAMETERS,

  variables: [],
  variablesCompare: [],
  variableKey: [],
  variableKeyCompare: [],

  savePromptData: {},

  variableOpen: false,
  isCompareMode: false,

  testVariables: [],

  responseFormat: '',
  jsonMode: false,

  responseFormatCompare: '',
  jsonModeCompare: false,

  isImageSupport: true,
  isAudioSupport: true,
}

const converstion = createSlice({
  name: 'conversation',
  initialState: initialStates,
  reducers: {
    setNewConversation: (state, action) => {
      state.conversation = action.payload
    },
    setIsPublished: (state, action) => {
      state.isPublished = action.payload
    },
    setSelectedAi: (state, action) => {
      state.selectedAi = action.payload
    },
    setSelectedAiCompare: (state, action) => {
      state.selectedAiCompare = action.payload
    },
    setSelectedKey: (state, action) => {
      state.selectedKey = action.payload
    },
    setParameters: (state, action) => {
      state.parameters = action.payload
    },
    setParametersCompare: (state, action) => {
      state.parametersCompare = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setPropmtData: (state, action) => {
      state.promptData = action.payload
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload
    },
    setMessages: (state, action) => {
      state.messages = [...state.messages, action.payload]
    },
    setMessagesInitial: (state, action) => {
      state.messages = action.payload
    },
    editMessage: (state, action) => {
      const { id, updatedMessage } = action.payload
      const messageIndex = state.messages.findIndex((message) => message._id === id)
      if (messageIndex !== -1) {
        state.messages[messageIndex].content = updatedMessage
      }
    },
    setSystemValue: (state, action) => {
      state.systemValue = action.payload
    },
    setChatSummary: (state, action) => {
      state.chatSummary = action.payload
    },
    setPromptDataCompare: (state, action) => {
      state.promptDataCompare = action.payload
    },
    setSelectedModelCompare: (state, action) => {
      state.selectedModelCompare = action.payload
    },
    setMessagesCompare: (state, action) => {
      state.messagesCompare = [...state.messagesCompare, action.payload]
    },
    setMessagesCompareInitial: (state, action) => {
      state.messagesCompare = action.payload
    },
    setSystemValueCompare: (state, action) => {
      state.systemValueCompare = action.payload
    },
    setChatSummaryCompare: (state, action) => {
      state.chatSummaryCompare = action.payload
    },
    setVariables: (state, action) => {
      state.variables = action.payload
    },
    setVariablesCompare: (state, action) => {
      state.variablesCompare = action.payload
    },
    setUserValue: (state, action) => {
      state.userValue = action.payload
    },
    setAssistantValue: (state, action) => {
      state.assistantValue = action.payload
    },
    setAssistantValueCompare: (state, action) => {
      state.assistantValueCompare = action.payload
    },
    setVariableKey: (state, action) => {
      state.variableKey = action.payload
    },
    setVariableCompareKey: (state, action) => {
      state.variableKeyCompare = action.payload
    },
    setUserValueCompare: (state, action) => {
      state.userValueCompare = action.payload
    },
    deleteMessage: (state, action) => {
      const messageId = action.payload
      state.messages = state.messages.filter((message) => message._id !== messageId)
    },
    editMessageCompare: (state, action) => {
      const { id, updatedMessage } = action.payload
      const messageIndex = state.messagesCompare.findIndex((message) => message._id === id)
      if (messageIndex !== -1) {
        state.messagesCompare[messageIndex].content = updatedMessage
      }
    },
    deleteMessageCompare: (state, action) => {
      const messageId = action.payload
      state.messagesCompare = state.messagesCompare.filter((message) => message._id !== messageId)
    },
    // Method to set or update savePromptData
    setSavePromptData: (state, action) => {
      state.savePromptData = { ...state.savePromptData, ...action.payload }
    },

    setVariableOpen: (state, action) => {
      state.variableOpen = action.payload
    },

    setIsCompareMode: (state, action) => {
      state.isCompareMode = action.payload
    },
    setTestVariables: (state, action) => {
      state.testVariables = action.payload
    },
    setResponseFormat: (state, action) => {
      state.responseFormat = action.payload
    },
    setJsonMode: (state, action) => {
      state.jsonMode = action.payload
    },
    setResponseFormatCompare: (state, action) => {
      state.responseFormatCompare = action.payload
    },
    setJsonModeCompare: (state, action) => {
      state.jsonModeCompare = action.payload
    },
    setIsImageSupport: (state, action) => {
      state.isImageSupport = action.payload
    },
    setIsAudioSupport: (state, action) => {
      state.isAudioSupport = action.payload
    },
  },
  extraReducers: (builder) => {
    // getPromptDetails
    builder.addCase(getPromptDetails.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getPromptDetails.fulfilled, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getPromptDetails.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(getVersionDetails.pending, (state, action) => {
      state.isVersionLoading = true
    })
    builder.addCase(getVersionDetails.fulfilled, (state, action) => {
      state.isVersionLoading = false
    })
    builder.addCase(getVersionDetails.rejected, (state, action) => {
      state.isVersionLoading = false
    })
  },
})

export const {
  setNewConversation,
  setIsPublished,
  setSelectedAi,
  setSelectedKey,
  setParameters,
  setIsLoading,
  setPropmtData,
  setSelectedModel,
  setMessages,
  setSystemValue,
  setChatSummary,
  setMessagesInitial,
  setPromptDataCompare,
  setSelectedModelCompare,
  setMessagesCompare,
  setMessagesCompareInitial,
  setSystemValueCompare,
  setChatSummaryCompare,
  setSelectedAiCompare,
  setParametersCompare,
  setVariables,
  editMessage,
  editMessageCompare,
  deleteMessage,
  deleteMessageCompare,
  setUserValue,
  setVariableKey,
  setUserValueCompare,
  setSavePromptData,
  setVariableCompareKey,
  setVariablesCompare,
  setVariableOpen,
  setAssistantValue,
  setAssistantValueCompare,
  setIsCompareMode,
  setTestVariables,
  setResponseFormat,
  setJsonMode,
  setResponseFormatCompare,
  setJsonModeCompare,
  setIsImageSupport,
  setIsAudioSupport,
} = converstion.actions

export default converstion.reducer
