import { Box, ListItem, styled } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

const MainBox = styled(Box)(({ theme }) => ({
  // width: 300,
  display: 'flex',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  height: '100%',
  padding: '16px',
  boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)',
  flexDirection: 'column',
}))

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
}))

const StyledSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  marginRight: '8px',
  color: theme.palette.primary.main,
}))

const StyledListItem = styled(ListItem)(({ theme, selectedP }) => ({
  borderRadius: '12px',
  marginBottom: '6px',
  backgroundColor: selectedP ? theme.palette.drawerButton : 'transparent',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.drawerButton,
  },
}))

export { MainBox, FlexBox, StyledSettingsIcon, StyledListItem }
