import { styled } from '@mui/material/styles'
import { Select, MenuItem, Popper, FormControl } from '@mui/material'

export const CustomSelect = styled(Select)(({ theme, bgcolor, color, height }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: bgcolor || theme.palette.secondary.main,
    color: color || theme.palette.primary.main,
    height: height || 'auto',
    padding: '10px',
  },
  '& .MuiSelect-select.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
}))

export const MenuItemStyled = styled(MenuItem)(
  ({ theme, hoverColor, selectedColor, firstIndex, lastIndex }) => ({
    color: theme.palette.primary.main,
    marginTop: firstIndex && '-8px',
    marginBottom: lastIndex && '-8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 14px',
    '&:hover': {
      backgroundColor: hoverColor || theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      backgroundColor: selectedColor || theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  }),
)

export const CustomPopper = styled(Popper)(({ theme, dropdownBgColor }) => ({
  '& .MuiMenu-paper': {
    backgroundColor: dropdownBgColor || theme.palette.secondary.main,
  },
}))

export const StyledDiv = styled('div')(({ theme, iconBgColor, iconColor }) => ({
  backgroundColor: iconBgColor || theme?.palette.secondary.main,
  color: iconColor || theme?.palette.studio.dropdown,
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))

export const StyledFormControl = styled(FormControl)(({ theme, width }) => ({
  backgroundColor: theme?.palette.secondary.main,
  width: width,
}))
