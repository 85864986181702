import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteKnowledgeBaseKey, fetchKnowledgeBaseKeys, addKnowledgeBaseKey, editKnowledgeBaseKey } from '../../redux/action/UserSettingsAction';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../util/helpers';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import { KEY_NAME_MAX_LENGTH, KEY_NAME_MIN_LENGTH } from '../../constants/Constants';

const AddKeyModal = ({ open, onClose, onAdd }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [keyName, setKeyName] = useState('');
  const [keyValue, setKeyValue] = useState('');
  const [nameError, setNameError] = useState('');
  const [keyError, setKeyError] = useState('');

  useEffect(() => {
    if (!open) {
      setKeyName('');
      setKeyValue('');
      setNameError('');
      setKeyError('');
      setIsLoading(false);
    }
  }, [open]);

  const validateInputs = () => {
    let isValid = true;
    if (!keyName.trim()) {
      setNameError('Key name is required');
      isValid = false;
    }
    if (!keyValue.trim()) {
      setKeyError('Key value is required');
      isValid = false;
    }
    return isValid;
  };

  const handleAddKey = () => {
    if (!validateInputs()) return;

    setIsLoading(true);
    try {
      dispatch(
        addKnowledgeBaseKey({
          obj: {
            key_name: keyName.trim(),
            key: keyValue.trim(),
          },
          meta: {
            onSuccess: (res) => {
              const tempObj = {
                _id: res?.data?.data?.key_id,
                key_name: res?.data?.data?.key_name,
                key: res?.data?.data?.key
              }
              onAdd(tempObj);
              toast.success(res?.data?.message);
              onClose();
            },
            onError: (error) => {
              if (error?.status === 409) {
                toast.error(error?.message || 'Key already exists');
              } else {
                toast.error(error?.message || 'Error adding key');
              }
              setIsLoading(false);
            },
          },
        })
      );
    } catch (error) {
      console.log('error adding key', error);
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography variant="h6" component="h2" mb={2}>
          Add Knowledge Base Key
        </Typography>
        <TextField
          label="Key Name"
          fullWidth
          required
          value={keyName}
          onChange={(e) => {
            setKeyName(e.target.value);
            setNameError('');
          }}
          error={!!nameError}
          helperText={nameError}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Key Value"
          fullWidth
          required
          value={keyValue}
          onChange={(e) => {
            setKeyValue(e.target.value);
            setKeyError('');
          }}
          error={!!keyError}
          helperText={keyError}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleAddKey}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Add Key'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const EditKeyModal = ({ open, onClose, keyData, onSubmit }) => {
  const [keyName, setKeyName] = useState('');
  const [keyValue, setKeyValue] = useState('');
  const [nameError, setNameError] = useState('');
  const [keyError, setKeyError] = useState('');

  useEffect(() => {
    if (keyData) {
      setKeyName(keyData.key_name || '');
      setKeyValue(keyData.key || '');
    }
    setNameError('');
    setKeyError('');
  }, [keyData]);

  const validateInputs = () => {
    let isValid = true;
    if (!keyName.trim()) {
      setNameError('Key name is required');
      isValid = false;
    }
    if (!keyValue.trim()) {
      setKeyError('Key value is required');
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateInputs()) return;
    onSubmit(keyData._id, { keyName: keyName.trim(), keyValue: keyValue.trim() });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
      }}>
        <Typography variant="h6" component="h2" mb={2}>
          Edit Knowledge Base Key
        </Typography>
        <TextField
          label="Key Name"
          fullWidth
          required
          value={keyName}
          onChange={(e) => {
            setKeyName(e.target.value);
            setNameError('');
          }}
          error={!!nameError}
          helperText={nameError}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Key Value"
          fullWidth
          required
          value={keyValue}
          onChange={(e) => {
            setKeyValue(e.target.value);
            setKeyError('');
          }}
          error={!!keyError}
          helperText={keyError}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>Save</Button>
        </Box>
      </Box>
    </Modal>
  );
};

const KeyTable = ({ keys, onDelete, onEditKey, isLoading }) => {
  const theme = useTheme();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingKey, setEditingKey] = useState(null);

  const handleCopy = (key) => {
    navigator.clipboard.writeText(key);
    toast.success('Key copied to clipboard');
  };

  const handleDeleteConfirm = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will delete the key.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
      }
    });
  };

  const handleEdit = (key) => {
    setEditingKey(key);
    setEditModalOpen(true);
  };

  const handleEditSubmit = (keyId, { keyName, keyValue }) => {
    onEditKey(keyId, keyName, keyValue);
    setEditModalOpen(false);
    setEditingKey(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (keys.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={200}>
        <Typography variant="h6">No knowledge base keys added yet. Click "Add New Key" to get started.</Typography>
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 3, maxHeight: '50vh', overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ userSelect: 'none' }}>Serial No.</TableCell>
              <TableCell sx={{ userSelect: 'none' }}>Name</TableCell>
              <TableCell sx={{ userSelect: 'none' }}>Key</TableCell>
              <TableCell sx={{ userSelect: 'none' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((key, index) => (
              <TableRow key={key._id}>
                <TableCell sx={{ userSelect: 'none' }}>{index + 1}</TableCell>
                <TableCell sx={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  userSelect: 'none'
                }}>
                  {key.key_name}
                </TableCell>
                <TableCell sx={{ userSelect: 'none' }}>{key.key.slice(0, 5) + '...'}</TableCell>
                <TableCell sx={{ userSelect: 'none' }}>
                  <IconButton onClick={() => handleCopy(key.key)} size="small">
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton onClick={() => handleEdit(key)} size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteConfirm(key._id)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <EditKeyModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setEditingKey(null);
        }}
        keyData={editingKey}
        onSubmit={handleEditSubmit}
      />
    </>
  );
};

const KnowledgeBaseKeys = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keys, setKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchKeys = () => {
    setIsLoading(true);
    try {
      dispatch(
        fetchKnowledgeBaseKeys({
          meta: {
            onSuccess: (res) => {
              setKeys(res?.data?.data?.map(key => ({
                _id: key.key_id,
                key_name: key.key_name,
                key: key.key,
                created_at: new Date()
              })) || []);
              setIsLoading(false);
            },
            onError: (error) => {
              toast.error('Error while fetching keys');
              setIsLoading(false);
            },
          },
        })
      );
    } catch (error) {
      console.log('error fetching keys', error);
      setIsLoading(false);
    }
  };

  const handleDeleteKey = (id) => {
    try {
      dispatch(
        deleteKnowledgeBaseKey({
          keyId: id,
          meta: {
            onSuccess: (res) => {
              setKeys(keys.filter(key => key._id !== id));
              toast.success(res?.data?.message);
            },
            onError: (error) => {
              toast.error('Error while deleting key');
            },
          },
        })
      );
    } catch (error) {
      console.log('error deleting key', error);
    }
  };

  const handleEditKey = (keyId, keyName, keyValue) => {
    try {
      dispatch(
        editKnowledgeBaseKey({
          keyId,
          keyName,
          keyValue,
          meta: {
            onSuccess: (res) => {
              setKeys(keys.map(key => 
                key._id === keyId ? { ...key, key_name: keyName, key: keyValue } : key
              ));
              toast.success(res?.data?.message);
            },
            onError: (error) => {
                if (error?.status === 409) {
                toast.error(error?.message || 'Key already exists');
              } else {
                toast.error(error?.message || 'Error while updating key');
              }
            },
          },
        })
      );
    } catch (error) {
      console.log('error updating key', error);
    }
  };

  useEffect(() => {
    fetchKeys();
  }, []);

  return (
    <Box padding={3}>
      <Typography variant="h4" style={{ color: theme.palette.primary.main, marginBottom: '1rem', userSelect: 'none' }}>
        Knowledge Base Keys
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: '1rem', border: `1px solid ${theme.palette.primary.main}`, borderRadius: '10px' }}
      >
        <Typography variant="h6" mb={2} sx={{ userSelect: 'none' }}>Manage Your Knowledge Base Keys</Typography>
        <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)}>
          Add New Key
        </Button>
        <KeyTable 
          keys={keys} 
          onDelete={handleDeleteKey} 
          onEditKey={handleEditKey}
          isLoading={isLoading}
        />
      </Paper>

      <AddKeyModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAdd={(newKey) => setKeys([...keys, newKey])}
      />
    </Box>
  );
};

export default KnowledgeBaseKeys;
