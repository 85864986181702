import React, { useState } from 'react'
import { Box, CssBaseline, IconButton, useTheme } from '@mui/material'
import KeyConfiguration from '../../components/settings/KeyConfiguration'
import TopBar from '../../components/TopBar'
import { USEISMENU } from '../../constants/Constants'
import Sidebar from '../../components/settings/sidebar'
import ApiKeys from '../../components/settings/ApiKeys'
import { StyledDrawer } from '../../components/studioDrawer/studioDrawerStyles'
import zIndex from '@mui/material/styles/zIndex'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import KnowledgeBaseKeys from '../../components/settings/KnowledgeBaseKeys'

// Add this new styled component
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: `calc(100vh - ${64}px)`,
  paddingTop: 64, // Add this to push the content below the TopBar
  zIndex: 0,
}))

const SettingsPage = () => {
  const [selectedSection, setSelectedSection] = useState('keyConfiguration')
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false)
  const theme = useTheme()
  const isMenu = USEISMENU()

  const toggleDrawer = (open) => {
    setSettingsDrawerOpen(open)
  }

  // Add this new function to handle selection and drawer closing
  const handleSectionSelect = (section) => {
    setSelectedSection(section)
    if (isMenu) {
      setSettingsDrawerOpen(false)
    }
  }

  const renderContent = () => {
    switch (selectedSection) {
      case 'keyConfiguration':
        return <KeyConfiguration />
      case 'apiKeys':
        return <ApiKeys />
      case 'knowledgeBaseKeys':
        return <KnowledgeBaseKeys />
      // case 'billingUsage':
      //   return <BillingUsage />
      // case 'subscription':
      //   return <Subscription />
      // case 'databaseConfig':
      //   return <DatabaseConfig />
      // default:
      //   return <KeyConfiguration />
    }
  }

  return (
    <>
      <CssBaseline />
      <TopBar toggleDrawer={toggleDrawer} />
      <StyledBox>
        <StyledDrawer
          anchor="left"
          open={isMenu ? settingsDrawerOpen : true}
          onClose={isMenu ? () => toggleDrawer(false) : undefined}
          {...(!isMenu && {
            variant: 'permanent',
            sx: {
              '& .MuiDrawer-paper': {
                top: 64, // Position the drawer below the TopBar
                height: 'calc(100% - 64px)', // Adjust the height
                zIndex: 0,
              },
            },
          })}
        >
          <Sidebar onSelect={handleSectionSelect} selected={selectedSection} />
        </StyledDrawer>
        
        <Box
          flexGrow={1}
          padding={3}
          sx={{ backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white' }}
        >
          {isMenu && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => toggleDrawer(true)}
              sx={{ mb: 1 , ml: 1}}
            >
              <MenuIcon color='primary' fontSize='large' />
            </IconButton>
          )}
          {renderContent()}
        </Box>
      </StyledBox>
    </>
  )
}

export default SettingsPage
