import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deletePromptStudioKey, fetchPromptStudioKeys, fetchUserKeys, generatePromptStudioKey, generateUserKey, editPromptStudioKey } from '../../redux/action/UserSettingsAction';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../util/helpers';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import { KEY_NAME_MAX_LENGTH, KEY_NAME_MIN_LENGTH } from '../../constants/Constants';

const GenerateKeyModal = ({ open, onClose, onGenerate }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [generatedKey, setGeneratedKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [keyName, setKeyName] = useState('');
  const [nameError, setNameError] = useState('');

  useEffect(() => {
    if (!open) {
      setGeneratedKey('');
      setIsLoading(false);
      setKeyName('');
      setNameError('');
    }
  }, [open]);

  const handleGenerate = () => {
    navigator.clipboard.writeText(generatedKey);
    // You might want to add a toast notification here
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedKey);
    // You might want to add a toast notification here
  };

  const validateKeyName = (name) => {
    if (!name.trim()) {
      return 'Key name is required';
    }
    if (name.trim().length < KEY_NAME_MIN_LENGTH) {
      return `Key name must be at least ${KEY_NAME_MIN_LENGTH} characters long`;
    }
    if (name.trim().length > KEY_NAME_MAX_LENGTH) {
      return `Key name cannot exceed ${KEY_NAME_MAX_LENGTH} characters`;
    }
    if (/^\d/.test(name.trim())) {
      return 'Key name cannot start with a number';
    }
    return '';
  };

  const handleGenerateKey = () => {
    const error = validateKeyName(keyName);
    if (error) {
      setNameError(error);
      return;
    }

    setIsLoading(true);
    try {
      dispatch(
        generatePromptStudioKey({
          keyName: keyName.trim(),
          meta: {
            onSuccess: (res) => {
              const newKey = res?.data?.data?.api_key;
              onGenerate(
                newKey, 
                res?.data?.data?.key_name,
                res?.data?.data?.created_at, 
                res?.data?.data?.key_id,
              );
              setGeneratedKey(newKey);
              toast.success(res?.data?.message);
              setIsLoading(false);
              setNameError('');
            },
            onError: (error) => {
              toast.error(error?.message);
              setIsLoading(false);
            },
          },
        })
      );
    } catch (error) {
      console.log('error for save', error);
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
      }}>
        <Typography variant="h6" component="h2" mb={2}>
          Generate API Key
        </Typography>
        <TextField
          label="Key Name"
          fullWidth
          required
          value={keyName}
          onChange={(e) => {
            setKeyName(e.target.value);
            setNameError('');
          }}
          error={!!nameError}
          helperText={nameError}
          inputProps={{
            maxLength: KEY_NAME_MAX_LENGTH
          }}
          sx={{ mb: 2 }}
        />
        <Typography variant="body1" mb={2}>
          This will be your API key. Please keep it secure and do not share it with others.
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={100}>
            <CircularProgress />
          </Box>
        ) : !generatedKey ? (
          <Button 
            variant="contained" 
            onClick={handleGenerateKey} 
            disabled={!keyName.trim()}
            alignSelf={'center'}
          >
            Generate
          </Button>
        ) : (
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <Typography variant="body1" mb={1}>
              Your new API key:
            </Typography>
            <TextField
              fullWidth
              value={generatedKey}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={handleCopy} edge="end">
                    <ContentCopyIcon />
                  </IconButton>
                ),
              }}
            />
            <Button variant="contained" onClick={onClose} sx={{ mt: 2, alignSelf: 'flex-end' }}>
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const EditKeyModal = ({ open, onClose, keyName: initialKeyName, onSubmit }) => {
  const [keyName, setKeyName] = useState(initialKeyName);
  const [nameError, setNameError] = useState('');

  useEffect(() => {
    setKeyName(initialKeyName);
    setNameError('');
  }, [initialKeyName]);

  const validateKeyName = (name) => {
    if (!name.trim()) {
      return 'Key name is required';
    }
    if (name.trim().length < KEY_NAME_MIN_LENGTH) {
      return `Key name must be at least ${KEY_NAME_MIN_LENGTH} characters long`;
    }
    if (name.trim().length > KEY_NAME_MAX_LENGTH) {
      return `Key name cannot exceed ${KEY_NAME_MAX_LENGTH} characters`;
    }
    if (/^\d/.test(name.trim())) {
      return 'Key name cannot start with a number';
    }
    return '';
  };

  const handleSubmit = () => {
    const error = validateKeyName(keyName);
    if (error) {
      setNameError(error);
      return;
    }
    onSubmit(keyName.trim());
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
      }}>
        <Typography variant="h6" component="h2" mb={2}>
          Edit API Key Name
        </Typography>
        <TextField
          label="Key Name"
          fullWidth
          required
          value={keyName}
          onChange={(e) => {
            setKeyName(e.target.value);
            setNameError('');
          }}
          error={!!nameError}
          helperText={nameError}
          inputProps={{
            maxLength: KEY_NAME_MAX_LENGTH
          }}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>Save</Button>
        </Box>
      </Box>
    </Modal>
  );
};

const ApiKeyTable = ({ apiKeys, setApiKeys, onDelete, onEditName, isLoading }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const Theme = useTheme();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingKey, setEditingKey] = useState(null);

  const handleCopy = (key) => {
    navigator.clipboard.writeText(key);
    // You might want to add a toast notification here
  };

  const handleDeleteConfirm = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will delete the key.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id)
      }
    })
  }

  const handleEdit = (key) => {
    setEditingKey(key);
    setEditModalOpen(true);
  };

  const handleEditSubmit = (newName) => {
    // This function should be passed down from the parent component
    // Call your API here and update the local state on success
    onEditName(editingKey._id, newName);
    setEditModalOpen(false);
    setEditingKey(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (apiKeys.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={200}>
        <Typography variant="h6">You haven't created any API keys yet. Click the "Genarate API Key" button to get started.</Typography>
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 3, maxHeight: '50vh', overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ userSelect: 'none' }}>Serial No.</TableCell>
              <TableCell sx={{ userSelect: 'none' }}>Name</TableCell>
              <TableCell sx={{ userSelect: 'none' }}>Key</TableCell>
              <TableCell sx={{ userSelect: 'none' }}>Created Date</TableCell>
              <TableCell sx={{ userSelect: 'none' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map((key, index) => (
              <TableRow key={key.id}>
                <TableCell sx={{ userSelect: 'none' }}>{index + 1}</TableCell>
                <TableCell sx={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  userSelect: 'none'
                }}>
                  {key.key_name}
                </TableCell>
                <TableCell sx={{ userSelect: 'none' }}>{key.key.slice(0, 5) + '...'}</TableCell>
                <TableCell sx={{ userSelect: 'none' }}>{formatDate(key.created_at)}</TableCell>
                <TableCell sx={{ userSelect: 'none' }}>
                  <IconButton onClick={() => handleCopy(key.key)} size="small">
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton onClick={() => handleEdit(key)} size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteConfirm(key._id)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <EditKeyModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setEditingKey(null);
        }}
        keyName={editingKey?.key_name || ''}
        onSubmit={handleEditSubmit}
      />
    </>
  );
};

const ApiKeys = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAppendKey = (newKey, key_name, created_at, id) => {
    const keyObject = {
      key: newKey,
      key_name: key_name,
      created_at: created_at,
      _id: id,
    };
    setApiKeys([...apiKeys, keyObject]);
  };

  const deleteUserKeyFn = (id) => {
    try {
      dispatch(
        deletePromptStudioKey({
          keyId : id,
          meta: {
            onSuccess: (res) => {
              // console.log(res)
              // setApiKeys(res?.data?.data?.api_keys)
              setApiKeys(apiKeys?.filter(key => key._id !== id));
              toast.success(res?.data?.message)
            },
            onError: (error) => {
              toast.error('Error while Deleting key')
            },
          },
        }),
      )
    } catch (error) {
      console.log('error for save', error)
    }
  }

  const fetchUserKeysFn = () => {
    setIsLoading(true);
    try {
      dispatch(
        fetchPromptStudioKeys({
          meta: {
            onSuccess: (res) => {
              setApiKeys(res?.data?.data?.api_keys);
              setIsLoading(false);
            },
            onError: (error) => {
              toast.error('Error while fetching keys');
              setIsLoading(false);
            },
          },
        })
      );
    } catch (error) {
      console.log('error for save', error);
      setIsLoading(false);
    }
  };

  const handleEditKeyName = (keyId, newName) => {
    try {
      dispatch(
        editPromptStudioKey({
          keyId,
          keyName: newName,
          meta: {
            onSuccess: (res) => {
              // Update the local state
              setApiKeys(apiKeys.map(key => 
                key._id === keyId ? { ...key, key_name: newName } : key
              ));
              toast.success(res?.data?.message);
            },
            onError: (error) => {
              toast.error('Error while updating key name');
            },
          },
        })
      );
    } catch (error) {
      console.log('error updating key name', error);
    }
  };

  useEffect(() => {
    fetchUserKeysFn()
  }, [])

  return (
    <Box padding={3}>
      <Typography variant="h4" style={{ color: theme.palette.primary.main, marginBottom: '1rem', userSelect: 'none' }}>
        API Keys
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: '1rem', border: `1px solid ${theme.palette.primary.main}`, borderRadius: '10px' }}
      >
        <Typography variant="h6" mb={2} sx={{ userSelect: 'none' }}>Manage Your API Keys</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenModal}>
          Generate API Key
        </Button>
        <ApiKeyTable apiKeys={apiKeys} setApiKeys={setApiKeys} onDelete={deleteUserKeyFn} onEditName={handleEditKeyName} isLoading={isLoading} />
      </Paper>

      <GenerateKeyModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onGenerate={handleAppendKey}
      />
    </Box>
  );
};

export default ApiKeys;
