import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Stack,
  Button,
  Link,
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { useState, useEffect } from 'react'
import {
  createCurlCommand,
  createNodeCommand,
  CURL_COMMAND,
  npmInstall,
} from '../../constants/Constants'
import { useAuth } from '../../AuthContext'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const EndpointDrawer = ({ endpointOpen, handleEndpointOpen }) => {
  const [copiedNpm, setCopiedNpm] = useState(false)
  const [copiedUsage, setCopiedUsage] = useState(false)
  const [copiedNodejs, setCopiedNodejs] = useState(false)
  const [copiedCurl, setCopiedCurl] = useState(false) // Add this line
  const [copiedUserId, setCopiedUserId] = useState(false)
  const [copiedPromptId, setCopiedPromptId] = useState(false)
  const { userInfo } = useAuth()
  const navigate = useNavigate()

  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const { selectedPrompt } = useSelector((state) => state.UserProjects)
  const { variables } = useSelector((state) => state.conversations)

  const requestPayloadFormat = (variables) => {
    return variables.reduce((acc, variable) => {
      acc[variable.key] = variable.value
      return acc
    }, {})
  }

  let user_id = userInfo?.userId

  useEffect(() => {
    if (copiedNpm || copiedUsage || copiedNodejs || copiedCurl || copiedUserId || copiedPromptId) {
      setTimeout(() => {
        setCopiedNpm(false)
        setCopiedUsage(false)
        setCopiedCurl(false)
        setCopiedUserId(false)
        setCopiedPromptId(false)
      }, 2300)
    }
  }, [copiedNpm, copiedUsage, copiedNodejs, copiedCurl, copiedUserId, copiedPromptId])

  const handleCopy = (content, setCopiedState) => {
    navigator.clipboard.writeText(content)
    setCopiedState(true)
    setTimeout(() => setCopiedState(false), 2000)
  }

  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const CodeBlock = ({ content, copied, onCopy }) => (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.endpoint.paper,
        padding: { xs: '12px', sm: '16px' },
        borderRadius: '4px',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'monospace',
          color: (theme) => theme.palette.endpoint.text,
          wordBreak: 'break-all',
          fontSize: { xs: '12px', sm: '14px' },
          whiteSpace: 'pre-wrap',
        }}
        component="pre"
      >
        {content}
      </Typography>
      <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
        <IconButton
          onClick={onCopy}
          size="small"
          sx={{
            color: (theme) => theme.palette.primary.main,
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          {copied ? <CheckIcon /> : <ContentCopyIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  )

  const handleGetKey = () => {
    navigate('/user/settings')
  }

  return (
    <>
      <Drawer
        open={endpointOpen}
        anchor="right"
        onClose={handleEndpointOpen}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '580px' },
            padding: { xs: '16px', sm: '24px' },
            backgroundColor: (theme) => theme.palette.endpoint.background,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: { xs: '0', sm: '8px' },
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Your Code
          </Typography>
          <IconButton onClick={handleEndpointOpen} size="large" edge="end" color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ marginY: 2 }} />

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="curl and node tabs"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Curl" />
          <Tab label="Node.js" />
        </Tabs>

        <Divider sx={{ marginY: 2 }} />

        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          {selectedTab === 0 && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.endpoint.paper,
                padding: { xs: '12px', sm: '16px' },
                borderRadius: '4px',
                overflow: 'auto',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'monospace',
                  color: (theme) => theme.palette.endpoint.text,
                  wordBreak: 'break-all',
                  marginBottom: '8px',
                  fontSize: { xs: '12px', sm: '14px' },
                }}
                component="pre"
              >
                {createCurlCommand(
                  baseUrl,
                  user_id,
                  selectedPrompt.promptId,
                  JSON.stringify(requestPayloadFormat(variables)),
                )}
              </Typography>

              <Tooltip title={copiedCurl ? 'Copied!' : 'Copy to clipboard'}>
                <IconButton
                  onClick={() =>
                    handleCopy(
                      createCurlCommand(
                        baseUrl,
                        user_id,
                        selectedPrompt.promptId,
                        JSON.stringify(requestPayloadFormat(variables)),
                      ),
                      setCopiedCurl,
                    )
                  }
                  size="small"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  {copiedCurl ? <CheckIcon /> : <ContentCopyIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {selectedTab === 1 && (
            <Box sx={{ marginTop: '16px' }}>
              <Typography variant="h6">Installation</Typography>
              <Stack spacing={2}>
                <Typography variant="h6">NPM</Typography>
                <CodeBlock
                  content={npmInstall}
                  copied={copiedNpm}
                  onCopy={() => handleCopy(npmInstall, setCopiedNpm)}
                />
                <Typography variant="h6">Prompt Id</Typography>
                <CodeBlock
                  content={selectedPrompt.promptId}
                  copied={copiedPromptId}
                  onCopy={() => handleCopy(selectedPrompt.promptId, setCopiedPromptId)}
                />
              </Stack>
              
              <Box sx={{ mt: 3, mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  To use this API, you'll need an API key. If you don't have one yet, or need to create a new one, click the button below:
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGetKey}
                  sx={{ mt: 1 }}
                >
                  Get your API Key
                </Button>
                <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
                  Note: Keep your API key secure and don't share it publicly.
                </Typography>
              </Box>
              <Box sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="body1">
                  For more information and the latest updates, visit our{' '}
                  <Link
                    href="https://www.npmjs.com/package/@n-promptstudio/sdk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    npm page
                  </Link>
                  .
                </Typography>
              </Box>

              {/* <Typography variant="h6" sx={{ marginTop: 1 }}>
                Usage
              </Typography>
              <CodeBlock
                content={createNodeCommand(user_id, selectedPrompt.promptId)}
                copied={copiedUsage}
                onCopy={() =>
                  handleCopy(createNodeCommand(user_id, selectedPrompt.promptId), setCopiedUsage)
                }
              /> */}
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  )
}

export default EndpointDrawer
