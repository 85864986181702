import React from 'react'
import { FormControl } from '@mui/material'
import {
  CustomSelect,
  MenuItemStyled,
  CustomPopper,
  StyledDiv,
  StyledFormControl,
} from './versionDropdownStyles'
import { useTheme } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { setSelectedPromptVersion } from '../../redux/slice/UserProjects'
import DoneAllIcon from '@mui/icons-material/DoneAll'

const VersionDropdown = ({
  menuList,
  selectedModel,
  setSelected,
  icon,
  width = '200px',
  id,
  buttonHeight = 'auto',
  buttonBgColor,
  buttonTextColor,
  menuHoverColor,
  dropdownBgColor,
  iconBgColor,
  iconColor,
  height = 'auto',
  disabled = false,
  category,
  isDispatch,
  publishedPromptVersions,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const handleChange = (event) => {
    dispatch(setSelectedPromptVersion(event.target.value))
  }
  return (
    <StyledFormControl variant="outlined" width={'auto'}>
      <CustomSelect
        id={id}
        value={selectedModel}
        onChange={handleChange}
        // IconComponent={() => <StyledDiv>{icon}</StyledDiv>}
        displayEmpty
        bgcolor={buttonBgColor}
        color={buttonTextColor}
        height={buttonHeight}
        PopperComponent={(props) => <CustomPopper {...props} dropdownBgColor={dropdownBgColor} />}
        style={{ height: height }}
        disabled={disabled}
      >
        {menuList &&
          Object.entries(menuList).map(([modelKey, modelValue], index) => (
            <MenuItemStyled
              key={modelKey}
              value={modelKey}
              hoverColor={menuHoverColor}
              selectedColor={buttonBgColor}
              firstIndex={index === 0}
              lastIndex={index === Object.entries(menuList).length - 1}
            >
              {modelValue.toString()}
              {publishedPromptVersions && publishedPromptVersions[modelKey] && (
                <DoneAllIcon
                  sx={{
                    marginLeft: '8px',
                    fontSize: '16px',
                    color: theme.palette.studio.dropdown,
                  }}
                />
              )}
            </MenuItemStyled>
          ))}
      </CustomSelect>
    </StyledFormControl>
  )
}

export default VersionDropdown
