export const authApi = {
  login: '/api/v1/login',
  refreshToken: 'api/v1/token/refresh',
}

export const userProjectApi = {
  userProjectDetails: (userId) => `api/v1/get/all/projects`,

  singleProjectDetails: (userId, projectId) => `api/v1/get/selected/project/${projectId}`,

  createDefaultProject: (userId) => `api/v1/post/default/project`,

  createProject: (userId) => `api/v1/post/create/project`,

  updateProjectName: (userId, projectId) => `api/v1/update/selected/project/${projectId}`,

  deactivateProject: (userId, projectId) => `api/v1/delete/selected/project/${projectId}`,

  deactivateFolder: (userId, projectId, folderId) =>
    `api/v1/delete/selected/folder/${projectId}/${folderId}`,

  deactivatePrompt: (userId, projectId, folderId, promptId) =>
    `api/v1/delete/selected/prompt/${projectId}/${folderId}/${promptId}`,

  updateFolderName: (userId, projectId, folderId) =>
    `api/v1/update/selected/folder/${projectId}/${folderId}`,

  updatePromptName: (userId, projectId, folderId, promptId) =>
    `api/v1/update/selected/prmopt/${projectId}/${folderId}/${promptId}`,

  createFolder: (userId, projectId) => `api/v1/post/create/folder/${projectId}`,

  createPrompt: (userId, projectId, folderId) =>
    `api/v1/post/create/prompt/${projectId}/${folderId}`,

  savePrompt: (userId, projectId, folderId, promptId, version) =>
    `api/v1/post/prompts/${projectId}/${folderId}/${promptId}/${version}`,

  fetchPlatforms: () => `api/v1/fetch/ai/platforms`,
  fetchModels: (platform) => `api/v1/ai-platform/models/${platform}`,
}

export const userSettings = {
  // Platform API Keys
  getUserKeys: (userId) => `api/v1/get/all/config`,
  addUserKey: (userId) => `api/v1/post/new/config`,
  updateUserKeys: (userId) => `api/v1/update/selected/config`,
  deleteUserKeys: (userId) => `api/v1/delete/selected/config`,

  // Prompt Studio API Keys
  generateUserKey: () => `api/v1/generate-api-key`,
  fetchUserKeys: () => `api/v1/fetch-api-keys`,
  deleteUserKey: (api_key_id) => `api/v1/delete-api-key/${api_key_id}`,
  editUserKey: (api_key_id) => `api/v1/update-api-key-name/${api_key_id}`,

  // Knowledge Base Keys
  addKnowledgeBaseKey: () => `/api/v1/save/knowledge-base/keys`,
  fetchKnowledgeBaseKeys: () => `api/v1/fetch/knowledge-base/keys`,
  deleteKnowledgeBaseKey: (knowledge_base_key_id) =>
    `api/v1/delete/knowledge-base/keys/${knowledge_base_key_id}`,
  editKnowledgeBaseKey: (knowledge_base_key_id) =>
    `api/v1/update/knowledge-base/keys/${knowledge_base_key_id}`,
}

export const playground = {
  geminiChat: () => `api/v1/gemini/chat`,
  openaiChat: () => `api/v1/openai/chat`,
  claudeChat: () => `api/v1/claude/chat`,

  chatWithModel: (userId) => `/api/v1/prompt/completion`,

  publishPrompt: () => `api/v1/post/prompts`,
  getPromptDetails: (userId, projectId, folderId, promptId) =>
    `api/v1/get/prompts/${projectId}/${folderId}/${promptId}`,

  getPromptVersions: (userId, projectId, folderId, prompt_id) =>
    `api/v1/get/versions/${projectId}/${folderId}/${prompt_id}`,

  // for new api
  updateIsPublished: (userId, projectId, folderId, promptId, versionId) =>
    `api/v1/update/isPublished/${projectId}/${folderId}/${promptId}/${versionId}`,

  getVersionDetails: (userId, projectId, folderId, promptId, versionId) =>
    `api/v1/fetch/prompts/${projectId}/${folderId}/${promptId}/${versionId}`,
}

export const testPromptApi = {
  chatWithPrompt: (userId, versionId) => `api/v1/post/chat_with_prompt/${versionId}`,
}

export const uploadFileEndpoint = `https://api.playground.promptstudio.dev/api/v1/upload_file`

export const shareAccount = {
  shareAccount: `api/v1/share-account`,
  getSharedToMe: `api/v1/get-shared-to-me`,
  getSharedByMe: `api/v1/get-shared-by-me`,

  removeSharedAccess: `api/v1/remove-shared-access`,
}
