import { useMediaQuery } from '@mui/material'
import { Images } from '../assets/image'
import { GB, IN, US, DE } from 'country-flag-icons/react/3x2'

const feature1 = Images.feature1
const feature2 = Images.feature2
const feature3 = Images.feature3
const feature4 = Images.feature4
const feature5 = Images.feature5
const feature6 = Images.feature6
const feature7 = Images.feature7

// Media Query

export const USEISMENU = () => {
  return useMediaQuery('(max-width: 1200px)')
}

export const USEISTAB = () => {
  return useMediaQuery('(max-width: 800px)')
}

export const USEISMOBILE = () => {
  return useMediaQuery('(max-width: 600px)')
}

export const USEISCOMPARETOGGLE = () => {
  return useMediaQuery('(max-width: 1875px)')
}

// Every Page Transition Effect

export const PAGETRANSITION = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

// Key Features

export const KEY_FEATURES = (t, ready) => [
  {
    title: ready ? t('landingPage.keyFeatures.advancedPromptManagement') : '',
    content: ready ? t('landingPage.keyFeatures.advancedPromptManagementContent') : '',
    image: feature1,
  },
  {
    title: ready ? t('landingPage.keyFeatures.guiBasedFineTuning') : '',
    content: ready ? t('landingPage.keyFeatures.guiBasedFineTuningContent') : '',
    image: feature2,
  },
  {
    title: ready ? t('landingPage.keyFeatures.realTimeHumanReinforcementLearning') : '',
    content: ready ? t('landingPage.keyFeatures.realTimeHumanReinforcementLearningContent') : '',
    image: feature3,
  },
  {
    title: ready ? t('landingPage.keyFeatures.llmDevOps') : '',
    content: ready ? t('landingPage.keyFeatures.llmDevOpsContent') : '',
    image: feature4,
  },
  {
    title: ready ? t('landingPage.keyFeatures.documentManager') : '',
    content: ready ? t('landingPage.keyFeatures.documentManagerContent') : '',
    image: feature5,
  },
  {
    title: ready ? t('landingPage.keyFeatures.ragOrchestration') : '',
    content: ready ? t('landingPage.keyFeatures.ragOrchestrationContent') : '',
    image: feature6,
  },
  {
    title: ready ? t('landingPage.keyFeatures.chatAssistedChatbotDevelopment') : '',
    content: ready ? t('landingPage.keyFeatures.chatAssistedChatbotDevelopmentContent') : '',
    image: feature7,
  },
]

export const API_TIMEOUT = 50000

export const RIGHT_DRAWER_WIDTH = 360

export const LEFT_DRAWER_WIDTH = 270

export const MODEL_OPTIONS = {
  openai: [
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-1106',
    'gpt-3.5-turbo-0125',
    'gpt-3.5-turbo-16k',
    'gpt-4-turbo',
    'gpt-4-turbo-preview',
    'gpt-4-turbo-2024-04-09',
    'gpt-4',
    'gpt-4-1106-preview',
    'gpt-4-0613',
    'gpt-4-0125-preview',
    'gpt-4o',
    'gpt-4o-2024-08-06',
    'gpt-4o-2024-05-13',
    'gpt-4o-mini',
    'gpt-4o-mini-2024-07-18',
    'chatgpt-4o-latest',
  ],
  claudeai: [
    'claude-3-opus-20240229',
    'claude-3-sonnet-20240229',
    'claude-3-haiku-20240307',
    'claude-3-5-sonnet-20240620',
    'claude-3-5-sonnet-20241022'
    // 'claude-3.5-sonnet-20240620',
    // 'claude-3-Opus-20240229',
    // 'claude-3-sonnet-20240229',
    // 'claude-3-haiku-20240307',
    // 'claude-2.1',
    // 'claude-2.0',
    // 'claude-instant-1.2',
  ],
  gemini: ['gemini-1.5-pro', 'gemini-1.5-flash'],
}

export const AI_MODELS = ['openai', 'claudeai', 'gemini']

export const API_KEY_LENGTHS = {
  openai: { min: 32, max: 1000 },
  claudeai: { min: 40, max: 1000 },
  gemini: { min: 32, max: 1000 },
}

export const DATA_SOURCE_LIST = (t, ready) => [
  {
    // category: t('studio.studioDrawer.datasource'),
    category: ready ? t('studio.studioDrawer.datasource') : 'Data Source',
    items: [
      ready ? t('studio.studioDrawer.database') : 'DataBase',
      ready ? t('studio.studioDrawer.externalApi') : 'External API',
      ready ? t('studio.studioDrawer.documents') : 'Documents',
      ready ? t('studio.studioDrawer.functions') : 'Functions',
    ],
  },
  {
    category: ready ? t('studio.studioDrawer.agents') : 'Agents',
    items: [],
  },
  {
    category: ready ? t('studio.studioDrawer.chatbots') : 'Chatbots',
    items: [],
    min: 32,
    max: 32,
  },
  {
    category: ready ? t('studio.studioDrawer.monitoring') : 'Monitoring',
    items: [],
  },
]

export const PARAM_SETTINGS = {
  openai: {
    temperature: { min: 0, max: 2, step: 0.01 },
    maxTokens: { min: 0, max: 4096, step: 1 },
    topP: { min: 0, max: 1, step: 0.01 },
    frequencyPenalty: { min: 0, max: 2, step: 0.01 },
    presencePenalty: { min: 0, max: 2, step: 0.01 },
  },
  claudeai: {
    temperature: { min: 0, max: 1, step: 0.19 },
    maxTokens: { min: 0, max: 4096, step: 1 },
  },
  gemini: {
    temperature: { min: 0, max: 2, step: 0.01 },
    maxTokens: { min: 0, max: 4096, step: 1 },
    topP: { min: 0, max: 1, step: 0.01 },
  },
}

export const DEFAULT_PARAMETERS = {
  openai: {
    temperature: 1,
    maxTokens: 1000,
    topP: 0.5,
    frequencyPenalty: 0.5,
    presencePenalty: 0.5,
  },
  claudeai: {
    temperature: 1,
    maxTokens: 1000,
  },
  gemini: {
    temperature: 1,
    maxTokens: 1000,
    topP: 1,
  },
}

// Menu items
export const PAGES_ITEMS = (t, ready) => [
  ready ? t('landingPage.menu.home') : 'Home',
  ready ? t('landingPage.menu.features') : 'Features',
  ready ? t('landingPage.menu.promptDevelopment') : 'Prompt Development',
  ready ? t('landingPage.menu.aboutUs') : 'About Us',
  ready ? t('landingPage.menu.contactUs') : 'Contact Us',
]

// website languages
export const LANGUAGES = [
  { code: 'english', name: 'EN', label: 'English (IN)', flag: IN },
  { code: 'english(uk)', name: 'EN', label: 'English (UK)', flag: GB },
  { code: 'english(us)', name: 'EN', label: 'English (US)', flag: US },
  { code: 'kannada', name: 'KN', label: 'Kannada', flag: IN },
  { code: 'hindi', name: 'HI', label: 'Hindi', flag: IN },
  { code: 'tamil', name: 'TA', label: 'Tamil', flag: IN },
  { code: 'marathi', name: 'MR', label: 'Marathi', flag: IN },
  { code: 'german', name: 'DE', label: 'GERMAN', flag: DE },
]

export const VERSIONS = ['version 1', 'version 2', 'version 3', 'version 4', 'version 5']

export const SAVE_ANIMATION_DURATION = 3000 //ms
export const SAVE_ANIMATION_AFTER = 2000 //ms

export const VARIABLE_HELPER_SYSTEM_VALUE =
  'Hello, {{userName}}! Welcome to {{companyName}}. Your account balance is ${{balance}}'

export const VARIABLE_HELPER_VAR_NAME = 'userName'
export const VARIABLE_HELPER_VAR_VALUE = 'John Doe'

// export const FOLDER_NAME_VALIDATION = (value, setError) => {
//   if (!value.trim()) {
//     setError('Name is required')
//     return false
//   }
//   if (value.trim().length < 3) {
//     setError('Name must be at least 3 characters long')
//     return false
//   }
//   if (/^\d/.test(value.trim())) {
//     setError('Name cannot start with a number')
//     return false
//   }
//   setError('')
//   return true
// }

export const NAMING_MIN = 3
export const NAMING_MAX = 30

export const MEMORY_INFO = {
  fullMemory: 'This option enables full memory usage.',
  windowMemory: 'This option enables window memory usage.',
  summarized: 'This option enables summarized memory usage.',
}

export const createCurlCommand = (baseUrl, userId, versionId, variables) =>
  `curl -X 'POST' \
      '${baseUrl}api/v1/post/chat_with_prompt/${userId}/${versionId}' \
      -H 'accept: application/json' \
      -H 'Content-Type: application/json' \
      -d '{
      "memory_type": "fullMemory",
      "window_size": 0,
      "user_message": "hi",
      "env": "test",
      "request_from": "api",
      "variables": ${variables}
    }'`

// Usage example
// const command = createCurlCommand("ram singh", "sdasdasd");

export const createNodeCommand = (prompt_id) => `
import PromptStudioManager from '@n-promptstudio/sdk';

(async () => {
    const promptManager = new PromptStudioManager({
        apiKey: 'YOUR_API_KEY',
        env: 'test'
    });

    try {
        const response = await promptManager.chatWithPromptV2('Prompt ID', {
            user_message: "Hello, how are you?",
            memory_type: "windowMemory",
            window_size: 10,
            session_id: "",
            env: "test",
            variables: {}
        });

        console.log(response);
    } catch (error) {
        console.log(error);
    }
})();`

export const npmInstall = `npm install @n-promptstudio/sdk`
export const yarnInstall = `yarn add @n-promptstudio/sdk`

export const JSON_SCHEMA_PLACEHOLDER = `{
  "name": "Sample_Schema",
  "strict": true,
  "schema": {
    "type": "object",
    "properties": {
      "question": {
        "type": "string",
        "description": "The complete question without grammatical mistakes."
      },
      "answer": {
        "type": "string",
        "description": "A concise answer, ideally four words."
      }
    },
    "required": [
      "question",
      "answer"
    ],
    "additionalProperties": false
  }
}`

export const OPENAI_JSON_EXAMPLE = `Response Format JSON Example:
{
  "name": "Sample_Schema",
  "strict": true,
  "schema": {
    "type": "object",
    "properties": {
      "question": {
        "type": "string",
        "description": "The complete question without grammatical mistakes."
      },
      "answer": {
        "type": "string",
        "description": "A concise answer, ideally four words."
      }
    },
    "required": [
      "question",
      "answer"
    ],
    "additionalProperties": false
  }
}
`

export const GEMINI_JSON_EXAMPLE = `Response Format JSON Example:
{
  "type": "object",
  "properties": {
    "question": {
      "type": "string"
    },
    "answer": {
      "type": "string"
    }
  },
  "required": [
    "question",
    "answer"
  ]
}
`

export const MODEL_FEATURES = {
  'gpt-4o-mini':               { audio: false, image: true },
  'gpt-4o-mini-2024-07-18':    { audio: false, image: true },
  'gpt-4o':                    { audio: false, image: true },
  'gpt-4o-2024-08-06':         { audio: false, image: true },
  'gpt-4o-2024-05-13':         { audio: false, image: true },

  'gpt-3.5-turbo':             { audio: false, image: false },
  'gpt-3.5-turbo-1106':        { audio: false, image: false },
  'gpt-3.5-turbo-0125':        { audio: false, image: false },
  'gpt-4-turbo':               { audio: false, image: true },
  'gpt-4-turbo-preview':      { audio: false, image: false },
  'gpt-4-turbo-2024-04-09':   { audio: false, image: true },
  'gpt-4':                     { audio: false, image: false },
  'gpt-4-1106-preview':       { audio: false, image: false },
  'gpt-4-0613':                { audio: false, image: false },
  'gpt-4-0125-preview':        { audio: false, image: false },
  'chatgpt-4o-latest':          { audio: false, image: true },

  'gemini-1.5-pro':            { audio: true, image: true },
  'gemini-1.5-flash':          { audio: true, image: true },
  'claude-3-opus-20240229':    { audio: false, image: true },
  'claude-3-sonnet-20240229':  { audio: false, image: true },
  'claude-3-haiku-20240307':   { audio: false, image: true },
  'claude-3-5-sonnet-20240620': { audio: false, image: true },
  'claude-3-5-sonnet-20241022': { audio: false, image: true },
}

export const KEY_NAME_MAX_LENGTH = 30
export const KEY_NAME_MIN_LENGTH = 3
