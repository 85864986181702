import React, { useState, useEffect } from 'react'
import { Typography, List, ListItem, ListItemText, IconButton, TextField, Tooltip, CircularProgress, Box, Button } from '@mui/material'
import { useAuth } from '../../AuthContext'
import {
  ButtonContainer,
  ColorBackground,
  EmailTypography,
  ImageContainer,
  MainContainer,
  ProfileButton,
  ProfileContainer,
  UsernameTypography,
  SectionContainer,
  ShareInputContainer,
  ScrollableList,
  LoadingContainer,
  NoDataContainer,
  ProfileHeader,
  ContentContainer,
  SidebarContainer,
  MainContent,
  StatusSection,
  UserInfo,
  UserName,
  UserEmail,
} from './profileStyles'
import { navigationRoutes } from '../../router/Navigation'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { shareAccount, getSharedToMe, getSharedByMe, removeSharedAccess } from '../../redux/action/shareAction'
import AddIcon from '@mui/icons-material/Add'
import { isValidEmail } from '../../util/validations'
import { getUserProjects } from '../../redux/action/UserProjectsAction'
import { setSelectedProject, setSelectedProjectId, setUserProjects } from '../../redux/slice/UserProjects'
  
const Profile = () => {
  const { userInfo, logout, switchAccount, selectedUserId, originalUserId } = useAuth()
  const navigate = useNavigate()
  const [shareEmail, setShareEmail] = useState('')
  const [sharedByMe, setSharedByMe] = useState([
    // Example structure
    { email: 'example@email.com', userName: 'User Name' }
  ])
  const [sharedToMe, setSharedToMe] = useState([
    // { email: 'sagar@promptstudio.dev', userName: 'Sagar' },
    // { email: 'sagarazsdf@promptstudio.dev', userName: 'Sagar' }
  ])
  // const [selectedUserId, setSelectedUserId] = useState(null)
  const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [sharedByMeLoading, setSharedByMeLoading] = useState(false)
  const [sharedToMeLoading, setSharedToMeLoading] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [currentViewEmail, setCurrentViewEmail] = useState(userInfo?.userEmail)

  const {
    selectedProjectId
  } = useSelector((state) => state.UserProjects)

  useEffect(() => {
    fetchSharedToMeFn()
    fetchSharedByMeFn()
  }, [])

  const handleLogoutClick = () => {
    logout()
    navigate(navigationRoutes.main.landingPage)
  }

  const handleShare = () => {
    if (shareEmail) {
      setSharedByMe([...sharedByMe, { 
        email: shareEmail, 
        userName: 'User Name' // This should come from API
      }])
      setShareEmail('')
    }
  }

  const handleRemoveShare = (emailToRemove) => {
    setSharedByMe(sharedByMe.filter(share => share.email !== emailToRemove))
  }

  const fetchUserProjects = (userId, isCreated = false) => {
    try {
      dispatch(
        getUserProjects({
          userId,
          meta: {
            onSuccess: (data) => {
              dispatch(setUserProjects(data?.data))
              const projectIds = Object.keys(data?.data)
              const lastProjectId = projectIds[projectIds.length - 1]
              const lastProjectName = data?.data[lastProjectId]
              if (isCreated) {
                dispatch(setSelectedProjectId(lastProjectId))
                dispatch(setSelectedProject(lastProjectName))
              }
              if (!projectIds.find((projectId) => projectId === selectedProjectId)) {
                dispatch(setSelectedProjectId(lastProjectId))
                dispatch(setSelectedProject(lastProjectName))
              }
            },
            onError: (error) => {
              console.error('Fetching failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during login:', error)
    }
  }

  const handleSwitchAccount = (account) => {
    switchAccount(account, (newUserId) => {
      setCurrentViewEmail(account.email)
      dispatch(setSelectedProject(''))
      dispatch(setSelectedProjectId(''))
      fetchUserProjects(newUserId)
    })
  }

  const handleShareAccount = () => {
    if (!shareEmail) {
      toast.warning('Please enter an email address')
      return
    }
    
    if (!isValidEmail(shareEmail)) {
      setEmailError('Please enter a valid email address')
      return
    }

    setSharedByMeLoading(true)
    dispatch(shareAccount({
      email: shareEmail,
      meta: {
        onSuccess: (data) => {
          toast.success('Account shared successfully')
          setShareEmail('')
          setEmailError('')
          fetchSharedByMeFn()
          setSharedByMeLoading(false)
        },
        onError: (error) => {
          toast.error(error?.data?.message || 'Failed to share account')
          setSharedByMeLoading(false)
        }
      }
    }))
  }

  const fetchSharedToMeFn = () => {
    setSharedToMeLoading(true)
    dispatch(getSharedToMe({
      meta: {
        onSuccess: (data) => {
          setSharedToMe(data?.data?.sharedToMe || [])
          setSharedToMeLoading(false)
        },
        onError: (error) => {
          toast.error(error?.data?.message || 'Failed to fetch shared accounts')
          setSharedToMe([])
          setSharedToMeLoading(false)
        }
      }
    }))
  }

  const fetchSharedByMeFn = () => {
    setSharedByMeLoading(true)
    dispatch(getSharedByMe({
      meta: {
        onSuccess: (data) => {
          setSharedByMe(data?.data?.sharedByMe || [])
          setSharedByMeLoading(false)
        },
        onError: (error) => {
          toast.error(error?.data?.message || 'Failed to fetch shared accounts')
          setSharedByMe([])
          setSharedByMeLoading(false)
        }
      }
    }))
  }

  const handleRemoveSharedAccess = (userId) => {
    dispatch(removeSharedAccess({
      userId,
      meta: {
        onSuccess: () => {
          toast.success('Shared access removed')
          setSharedByMe(sharedByMe.filter(share => share.userId !== userId))
        },
        onError: (error) => {
          toast.error(error?.data?.message || 'Failed to remove shared access')
        }
      }
    }))
  }

  const handleSwitchToOriginal = () => {
    const originalUserData = {
      userId: originalUserId,
      email: userInfo.userEmail,
      userName: userInfo.userName
    }
    switchAccount(originalUserData, (newUserId) => {
      setCurrentViewEmail(userInfo.userEmail)
      dispatch(setSelectedProject(''))
      dispatch(setSelectedProjectId(''))
      fetchUserProjects(newUserId)
    })
  }

  return (
    <MainContainer>
      <ProfileHeader>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <ImageContainer src={userInfo?.userProfile} alt="Profile" />
            <UserInfo>
              <UserName>{userInfo?.userName}</UserName>
              <UserEmail>{userInfo?.userEmail}</UserEmail>
            </UserInfo>
          </Box>
          {selectedUserId !== originalUserId && (
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ mt: 1, display: 'block' }}
            >
              Currently viewing projects of: {currentViewEmail}
            </Typography>
          )}
        </Box>
      </ProfileHeader>

      <ContentContainer>
        <SidebarContainer>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6">Shared with Me</Typography>
            <ScrollableList>
              {sharedToMeLoading ? (
                <LoadingContainer>
                  <CircularProgress size={24} />
                </LoadingContainer>
              ) : (
                <List>
                  {/* Original Account Item - Always first in the list */}
                  {selectedUserId !== originalUserId && (
                    <ListItem
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'background.default',
                      }}
                      secondaryAction={
                        <Tooltip title="Switch to original account">
                          <IconButton 
                            edge="end" 
                            onClick={handleSwitchToOriginal}
                            size="small"
                            color="primary"
                          >
                            <CloudSyncIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      <ListItemText 
                        primary={userInfo?.userEmail}
                        secondary="Original Account"
                        primaryTypographyProps={{
                          style: { fontWeight: 500 }
                        }}
                      />
                    </ListItem>
                  )}

                  {/* Shared Accounts List */}
                  {sharedToMe?.length === 0 ? (
                    <NoDataContainer>
                      <Typography>No shared accounts available</Typography>
                    </NoDataContainer>
                  ) : (
                    sharedToMe?.map((account) => (
                      <ListItem
                        key={account?.email}
                        sx={{
                          backgroundColor: selectedUserId === account.userId 
                            ? 'action.selected' 
                            : 'transparent',
                        }}
                        secondaryAction={
                          <Tooltip title="Switch to this account">
                            <IconButton 
                              edge="end" 
                              onClick={() => handleSwitchAccount(account)}
                              disabled={selectedUserId === account.userId}
                              size="small"
                              color="primary"
                            >
                              <CloudSyncIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        }
                      >
                        <ListItemText 
                          primary={account?.email} 
                          secondary={account?.userName}
                        />
                      </ListItem>
                    ))
                  )}
                </List>
              )}
            </ScrollableList>
          </Box>

          <ProfileButton
            variant="outlined"
            color="error"
            onClick={handleLogoutClick}
            fullWidth
          >
            Logout
          </ProfileButton>
        </SidebarContainer>

        <MainContent>
          <SectionContainer>
            <Typography variant="h6">Share Account</Typography>
            <ShareInputContainer>
              <TextField
                size="small"
                value={shareEmail}
                onChange={(e) => {
                  setShareEmail(e.target.value)
                  setEmailError('')
                }}
                placeholder="Enter email to share"
                error={!!emailError}
                helperText={emailError}
              />
              <Button
                variant="contained"
                onClick={handleShareAccount}
                disabled={sharedByMeLoading}
                startIcon={<AddIcon />}
                size="small"
              >
                Add User
              </Button>
            </ShareInputContainer>

            <ScrollableList>
              {sharedByMeLoading ? (
                <LoadingContainer>
                  <CircularProgress size={24} />
                </LoadingContainer>
              ) : sharedByMe.length === 0 ? (
                <NoDataContainer>
                  <Typography>No users shared with yet</Typography>
                </NoDataContainer>
              ) : (
                <List>
                  {sharedByMe.map((share) => (
                    <ListItem
                      key={share.email}
                      secondaryAction={
                        <IconButton 
                          edge="end" 
                          onClick={() => handleRemoveSharedAccess(share.userId)}
                          size="small"
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      }
                    >
                      <ListItemText 
                        primary={share.email} 
                        secondary={share.userName}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </ScrollableList>
          </SectionContainer>
        </MainContent>
      </ContentContainer>
    </MainContainer>
  )
}

export default Profile
