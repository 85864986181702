import { Box, styled, Typography, Button } from '@mui/material'

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '85vh',
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    height: '100vh',
    maxHeight: '100vh',
  },
}))

const ProfileHeader = styled(Box)(({ theme }) => ({
  padding: '16px 24px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
  minHeight: '70px',

  [theme.breakpoints.down('sm')]: {
    padding: '12px 16px',
    minHeight: '60px',
  },
}))

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  padding: '16px',
  gap: '16px',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    gap: '12px',
    overflow: 'auto',
  },
}))

const SidebarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  height: '100%',
  
  [theme.breakpoints.up('md')]: {
    width: '280px',
    minWidth: '280px',
    overflow: 'auto',
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    overflow: 'visible',
  },
}))

const ShareInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginBottom: '16px',
  
  '& .MuiTextField-root': {
    width: '100%',
  },

  '& .MuiButton-root': {
    width: '100%',
  },
}))

const ScrollableList = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.02)' : 'rgba(0, 0, 0, 0.01)',
  minHeight: '150px', // Reduced minimum height
  maxHeight: '250px', // Reduced maximum height for mobile

  '& .MuiList-root': {
    padding: 0,
  },

  '& .MuiListItem-root': {
    padding: '8px 12px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  '& .MuiListItemText-root': {
    margin: '4px 0',
    paddingRight: '48px',
  },

  '& .MuiListItemText-primary': {
    fontSize: '0.9rem',
  },

  '& .MuiListItemText-secondary': {
    fontSize: '0.8rem',
  },

  '& .MuiListItemSecondaryAction-root': {
    right: '8px',
    display: 'flex',
    alignItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    minHeight: '120px',
    maxHeight: '200px',
  },
}))

const SectionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  padding: '16px',
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  
  '& .MuiTypography-h6': {
    marginBottom: '12px',
    fontWeight: 600,
    fontSize: '1rem',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '12px',
  },
}))

const ImageContainer = styled('img')(({ theme }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.background.paper,
  flexShrink: 0,
}))

const ProfileButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 12px',
  borderRadius: '6px',
  fontWeight: 500,
  minHeight: '36px',
}))

const LoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  minHeight: '100px',
}))

const NoDataContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px 16px',
  color: theme.palette.text.secondary,
  minHeight: '100px',
  fontSize: '0.9rem',
}))

// New components for better organization
const UserInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  flex: 1,
  minWidth: 0, // Prevent text overflow
}))

const UserName = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '1.1rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const UserEmail = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.9rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const StatusSection = styled(Box)(({ theme }) => ({
  padding: '16px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}))

export {
  MainContainer,
  ProfileHeader,
  ContentContainer,
  SidebarContainer,
  MainContent,
  ImageContainer,
  ProfileButton,
  SectionContainer,
  ShareInputContainer,
  ScrollableList,
  LoadingContainer,
  NoDataContainer,
  UserInfo,
  UserName,
  UserEmail,
  StatusSection,
}
