import React, { createContext, useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [originalUserId, setOriginalUserId] = useState(null)
  const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)

  useEffect(() => {
    const encodedData = localStorage.getItem(STORAGE.USER_INFO)
    const savedSelectedUserId = localStorage.getItem(STORAGE.SELECTED_USER_ID)
    
    if (encodedData) {
      try {
        const decodedData = atob(encodedData)
        const userData = JSON.parse(decodedData)
        setUserInfo(userData)
        
        if (!localStorage.getItem(STORAGE.ORIGINAL_USER_ID)) {
          localStorage.setItem(STORAGE.ORIGINAL_USER_ID, userData.userId)
        }
        setOriginalUserId(localStorage.getItem(STORAGE.ORIGINAL_USER_ID))
        
        if (!savedSelectedUserId) {
          setSelectedUserId(userData.userId)
          localStorage.setItem(STORAGE.SELECTED_USER_ID, userData.userId)
        } else {
          setSelectedUserId(savedSelectedUserId)
        }
      } catch (error) {
        console.error('Failed to decode user data from local storage', error)
      }
    }
  }, [])

  const login = async (userData) => {
    try {
      // First set all the data
      const user_info = JSON.stringify(userData?.data?.user)
      const userId = userData?.data?.user?.userId

      // Set all localStorage items
      localStorage.setItem(STORAGE.USER_INFO, btoa(user_info))
      localStorage.setItem(STORAGE.TOKEN, btoa(userData?.data?.access_token))
      localStorage.setItem(STORAGE.REFRESH, btoa(userData?.data?.refresh_token))
      
      // Set both selectedUserId and originalUserId to the same value during fresh login
      localStorage.setItem(STORAGE.SELECTED_USER_ID, userId)
      localStorage.setItem(STORAGE.ORIGINAL_USER_ID, userId)

      // Update state
      setUserInfo(userData?.data?.user)
      setSelectedUserId(userId)
      setOriginalUserId(userId)

      return Promise.resolve()
    } catch (error) {
      console.error('Login error:', error)
      return Promise.reject(error)
    }
  }

  const logout = async () => {
    const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
    localStorage.removeItem(STORAGE.TOKEN)
    localStorage.removeItem(STORAGE.REFRESH)
    localStorage.removeItem(STORAGE.USER_INFO)
    localStorage.removeItem(STORAGE.SELECTED_USER_ID)
    localStorage.removeItem('persist:root')
    setSelectedUserId(null)
    toast.success("You're logged out. Remember, you're always welcome back!")
  }

  const switchAccount = (accountInfo, onSwitchComplete) => {
    setSelectedUserId(accountInfo.userId)
    localStorage.setItem(STORAGE.SELECTED_USER_ID, accountInfo.userId)
    
    // Call the callback function if provided
    if (onSwitchComplete) {
      onSwitchComplete(accountInfo.userId)
    }
  }

  return (
    <AuthContext.Provider 
      value={{ 
        userInfo, 
        login, 
        logout, 
        selectedUserId, 
        originalUserId,
        switchAccount 
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export default AuthContext
