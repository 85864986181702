import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../util/axios'
import { userSettings as Api } from '../../services/Api'

export const getUserKeys = createAsyncThunk('getApiKeys', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.getUserKeys(payload.userId))
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const addUserKey = createAsyncThunk('addUserKey', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.addUserKey(payload.userId), payload.obj)
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const updateUserKeys = createAsyncThunk('updateUserKeys', async (payload) => {
  try {
    const res = await axiosInstance.patch(Api.updateUserKeys(payload.userId), payload.obj)
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const deleteUserKeys = createAsyncThunk('deleteUserKeys', async (payload) => {
  try {
    const res = await axiosInstance.delete(Api.deleteUserKeys(payload.userId), {
      data: payload.obj,
    })
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const generatePromptStudioKey = createAsyncThunk('generateUserKey', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.generateUserKey(payload.userId, payload.keyName), {
      key_name: payload.keyName,
    })
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const fetchPromptStudioKeys = createAsyncThunk('fetchUserKeys', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.fetchUserKeys())
   if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const deletePromptStudioKey = createAsyncThunk('deleteUserKey', async (payload) => {
  try {
    const res = await axiosInstance.delete(Api.deleteUserKey(payload.keyId))
   if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const editPromptStudioKey = createAsyncThunk('editUserKey', async (payload) => {
  try {
    const res = await axiosInstance.patch(Api.editUserKey(payload.keyId), {
      key_name: payload.keyName,
    })
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

// Knowledge Base Keys
export const addKnowledgeBaseKey = createAsyncThunk('addKnowledgeBaseKey', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.addKnowledgeBaseKey(), payload.obj)
    if (res?.status === 201 || res?.status === 200) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.data)
      throw res?.data
    }
  } catch (error) {
    if (error.response?.status === 409) {
      payload.meta?.onError?.(error.response.data)
      return error.response.data
    }
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const fetchKnowledgeBaseKeys = createAsyncThunk('fetchKnowledgeBaseKeys', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.fetchKnowledgeBaseKeys())
    if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const deleteKnowledgeBaseKey = createAsyncThunk('deleteKnowledgeBaseKey', async (payload) => {
  try {
    const res = await axiosInstance.delete(Api.deleteKnowledgeBaseKey(payload.keyId))
    if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const editKnowledgeBaseKey = createAsyncThunk('editKnowledgeBaseKey', async (payload) => {
  try {
    const res = await axiosInstance.patch(Api.editKnowledgeBaseKey(payload.keyId), {
      key_name: payload.keyName,
      key: payload.keyValue,
    })
    if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else
     {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    if (error.response?.status === 409) {
      payload.meta?.onError?.(error.response.data)
      return error.response.data
    }
    payload.meta?.onError?.(error)
    return error.message
  }
})
