import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../util/axios'
import { shareAccount as Api } from '../../services/Api'

export const shareAccount = createAsyncThunk('shareAccount', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.shareAccount, { email: payload.email })
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getSharedToMe = createAsyncThunk('getSharedToMe', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.getSharedToMe)
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getSharedByMe = createAsyncThunk('getSharedByMe', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.getSharedByMe)
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const removeSharedAccess = createAsyncThunk('removeSharedAccess', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.removeSharedAccess, { userId: payload.userId })
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
