import { MODEL_FEATURES } from "../../constants/Constants"

export const isAllVariablePresent = (variablesArray, variableKeys) => {
  const temp = variableKeys.every((key) => {
    const variable = variablesArray.find((item) => item.key.toLowerCase() === key.toLowerCase())
    return variable && variable.value && variable.value.trim() !== ''
  })
  return temp
}

export const replaceVariables = (content, variables) => {
  let parsedContent;
  try {
    parsedContent = JSON.parse(content);
  } catch (error) {
    parsedContent = content;
  }

  if (typeof parsedContent === 'object') {
    let stringContent = JSON.stringify(parsedContent);
    variables?.forEach((variable) => {
      const regex = new RegExp(`{{\\s*${variable.key}\\s*}}`, 'g');
      stringContent = stringContent.replace(regex, variable.value);
    });
    return stringContent;
  } else {
    variables?.forEach((variable) => {
      const regex = new RegExp(`{{\\s*${variable.key}\\s*}}`, 'g');
      parsedContent = parsedContent.replace(regex, variable.value);
    });
    return String(parsedContent);
  }
}

export const checkMessageSequence = (messages) => {
  if (messages.length === 0) return true

  if (messages.length === 1) return false

  if (messages[0].role !== 'user') return false

  for (let i = 0; i < messages.length; i++) {
    const expectedRole = i % 2 === 0 ? 'user' : 'assistant'
    if (messages[i].role !== expectedRole) return false
  }

  if (messages[messages.length - 1].role === 'user') return false

  return true
}

export const generateNewVersion = (promptVersions) => {
  const latestVersionKey = Object.keys(promptVersions).reduce((a, b) =>
    promptVersions[a] > promptVersions[b] ? a : b,
  )
  const latestVersionValue = promptVersions[latestVersionKey]
  const versionParts = latestVersionValue.toString().split('.').map(Number)

  // Increment version logic
  if (versionParts.length === 1) {
    versionParts.push(0) // Start with 1.0 if only major version exists
  }

  if (versionParts[1] < 9) {
    versionParts[1] += 1
  } else {
    versionParts[0] += 1
    versionParts[1] = 0
  }

  const newVersion = parseFloat(versionParts.join('.'))
  return newVersion
}


export const formatDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = dateObj.toLocaleString('default', { month: 'short' }).toUpperCase();
  const year = dateObj.getFullYear();
  
  return `${day} ${month} ${year}`;
}

export const checkImageSupport = (messages, selectedCategory) => {
  const hasImages = messages.some(message => 
    message.content.some(item => item.type === 'file' || item.type === 'image')
  );
  
  if (!hasImages) {
    return true; // No images, so it's supported
  }
  
  // Check if the selected category supports images
  return MODEL_FEATURES[selectedCategory]?.image === true;
}

